import React, { useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagManager } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import { tagManagerPurchased } from '@ameroservices-platform/attraction-frontend/app/services/googleTagManagerService/googleTagManagerService';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import CircularProgress from '@mui/material/CircularProgress';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import orderState from '@ameroservices-platform/shared/enums/orderState';
import { useTranslation } from 'react-i18next';
import { cartCheck } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';

function CartComplete() {
	const authUser = useSelector(({ auth }) => auth.user);
	const googleTagManager = useSelector(selectGoogleTagManager);
	const runGTMOnce = useRef(false);
	const runCartCheckOnce = useRef(false);
	const [order, setOrder] = useState(undefined);
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const customerUid = authUser.uid;
	const dispatch = useDispatch();

	const successType = useMemo(() => {
		if (searchParams.get('fs') === 'true') {
			return 'autoRenewalActivated';
		}
		return 'order';
	}, [searchParams]);

	const orderUid = useMemo(() => {
		return searchParams.get('fo');
	}, [searchParams]);

	const state = useMemo(() => {
		if (successType === 'autoRenewalActivated') {
			return 'success';
		}
		if (!order) {
			return 'loading';
		}
		if (order.state === 'error') {
			return 'error';
		}
		if ([orderState.PENDING_PAYMENT, orderState.PROCESSING].includes(order.state)) {
			return 'loading';
		}
		return 'success';
	}, [order, successType]);

	useEffect(() => {
		if (googleTagManager && order?.payments?.[0]?.transactionId && !runGTMOnce.current) {
			runGTMOnce.current = true;
			tagManagerPurchased(order);
		}
	}, [googleTagManager, order]);

	useEffect(() => {
		if (order?.state === orderState.COMPLETED && runCartCheckOnce.current === false) {
			runCartCheckOnce.current = true;
			dispatch(cartCheck());
		}
	}, [dispatch, order]);

	useEffect(() => {
		if (customerUid && orderUid) {
			const db = firebaseService.getOrganisationRootDB();
			return db
				.collection('customers')
				.doc(customerUid)
				.collection('orders')
				.doc(orderUid)
				.onSnapshot(doc => {
					setOrder({ ...doc.data(), id: doc.id });
				});
		}
		return () => {};
	}, [customerUid, orderUid]);

	return (
		<div className="flex flex-col items-center justify-center w-full py-40">
			<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
				<Card className={'max-w-[44rem]'}>
					<CardContent className="flex justify-center items-center flex-col p-16 pb-48 sm:p-24 md:p-32">
						{state === 'error' && (
							<HighlightOffIcon
								fontSize={'inherit'}
								sx={{
									fontSize: '65px'
								}}
								color={'error'}
							/>
						)}
						{state === 'success' && (
							<CheckCircleOutlineIcon
								fontSize={'inherit'}
								sx={{
									fontSize: '65px'
								}}
								color={'success'}
							/>
						)}
						{state === 'loading' && (
							<CircularProgress
								fontSize={'inherit'}
								sx={{
									fontSize: '65px'
								}}
								color={'success'}
							/>
						)}
						{successType === 'autoRenewalActivated' && (
							<>
								<Typography
									variant="h6"
									className="text-center mt-12 font-bold text-28 leading-8 mb-14"
								>
									{t('CART_AUTOMATIC_RENEWAL_ACTIVATED')}
								</Typography>
								<Typography className="text-center">
									{t('CART_AUTOMATIC_RENEWAL_ACTIVATED_DESC')}
								</Typography>
							</>
						)}
						{successType === 'order' && (
							<>
								{order && (
									<>
										{state === 'success' && (
											<>
												<Typography variant="h6" className="mt-12 font-bold text-28">
													{t('CART_SUCCESS_TITLE')}
												</Typography>
												<Typography className="mb-20">
													{t('CART_YOUR_ORDERNUMBER')} <strong>{order?.orderNumber}</strong>
												</Typography>
												<Typography className="text-center">
													{t('CART_SUCCESS_DESC')}{' '}
													<span className={'font-bold'}>
														{order?.contactInfo?.billing?.email}
													</span>
												</Typography>
											</>
										)}
										{state === 'loading' && (
											<>
												<Typography variant="h6" className="mt-12 font-bold text-28">
													{t('CART_PROCCESSING_TITLE')}
												</Typography>
												<Typography className="mb-20">
													{t('CART_YOUR_ORDERNUMBER')} <strong>{order.orderNumber}</strong>
												</Typography>
												<Typography className="text-center">
													{t('CART_LOADING_DESC')}{' '}
													<span className={'font-bold'}>
														{order?.contactInfo?.billing?.email}
													</span>
												</Typography>
											</>
										)}
										{state === 'error' && (
											<>
												<Typography
													variant="h6"
													className="text-center mt-12 font-bold text-28 leading-8 mb-14"
												>
													{t('CART_ERROR_TITLE')}
												</Typography>
												<Typography className="mb-20">
													{t('CART_YOUR_ORDERNUMBER')} <strong>{order.orderNumber}</strong>
												</Typography>
												<Typography className="text-center">{t('CART_ERROR_DESC')}</Typography>
											</>
										)}
									</>
								)}
								{!order && (
									<>
										<Typography variant="h6" className="mt-12 font-bold text-28">
											{t('CART_PROCCESSING_TITLE')}
										</Typography>
										<Typography className="mb-20 text-center">
											{t('CART_PROCESSING_DESC_NO_ORDER')}
										</Typography>
									</>
								)}
							</>
						)}
						<Typography className="text-center mt-20" variant={'caption'}>
							{t('YOU_CAN_CLOSE_THIS_WINDOW')}
						</Typography>
					</CardContent>
				</Card>
			</motion.div>
		</div>
	);
}

export default CartComplete;
