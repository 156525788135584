import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { NavigateNext } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTitle, Checkbox, FormControlLabel, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import {
	selectGoogleTagManager,
	selectModules,
	selectTheme,
	mergeThemes
} from '../../../../../../../../store/shared/frontendSlice';
import CartContentOverview from './CartContentOverview';
import {
	tagManagerBeginCheckOut,
	tagManagerViewCart
} from '../../../../../../../../services/googleTagManagerService/googleTagManagerService';
import CartRedeemCode from './CartRedeemCode';
import makeStyles from '@mui/styles/makeStyles';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import clsx from 'clsx';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import productType from '@ameroservices-platform/shared/enums/productType';
import {
	requestCartDraft,
	selectCart,
	selectCartOrderLines,
	setCartError,
	verifyCart,
	updateCartDraft,
	addProductsToOrder,
	selectCartLoadingById,
	selectCartError,
	selectCartDraft,
	cloneOrder,
	applyCode
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import CartEmbeddedDialog from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/content/CartTermsDialog';

function CartContent(props) {
	const { t } = useTranslation();
	const { contentElementProps } = props;
	const flags = useFlags();
	const dispatch = useDispatch();
	const location = useLocation();
	const locationState = location.state || {};
	const modules = useSelector(selectModules);
	const googleTagManager = useSelector(selectGoogleTagManager);
	const user = useSelector(({ auth }) => auth.user);

	const orderLoading = useSelector(state => selectCartLoadingById(state, 'order'));
	const allOrderLinesLoading = useSelector(state => selectCartLoadingById(state, 'orderLines'));
	const cartCheckLoading = useSelector(state => selectCartLoadingById(state, 'cartCheck'));
	const cartError = useSelector(selectCartError);
	const cart = useSelector(selectCart);
	const orderDraft = useSelector(selectCartDraft);
	const orderLines = useSelector(selectCartOrderLines);
	const [enableAutomaticRenewal, setEnableAutomaticRenewal] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [acceptedSubscriptionTerms, setAcceptedSubscriptionTerms] = useState(false);
	const [openTermsDialog, setOpenTermsDialog] = useState(false);
	const [openSubscriptionTermsDialog, setOpenSubscriptionTermsDialog] = useState(false);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const cloneCustomerUid = searchParams.get('c');
		const cloneOrderUid = searchParams.get('o');
		const isInfoTab = searchParams.get('n');
		if (cloneCustomerUid && cloneOrderUid) {
			cloneOrder(cloneCustomerUid, cloneOrderUid).finally(() => {
				setSearchParams({});
			});
			if (isInfoTab) {
				setSearchParams({ co: cloneOrderUid });
			}
		}
	}, [searchParams]);

	const useStyles = makeStyles(_theme => ({
		termsButton: {
			color:
				contentElementProps.termsButtonBackgroundColor && contentElementProps.termsButtonCustomBackgroundColor
					? getColor(
							contentElementProps.termsButtonBackgroundColor,
							contentElementProps.termsButtonCustomBackgroundColor,
							mergedTheme
					  )
					: undefined
		}
	}));
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		if (
			!cartCheckLoading &&
			cart &&
			user.cartOrderUid === cart.id &&
			cart.state === 'pendingPayment' &&
			cart.payments &&
			cart.payments.length > 0 &&
			cart.payments[0].prepData &&
			!cart.payments[0].prepData.framed
		) {
			if (cart.payments[0].prepData.state === 'pending' && cart.payments[0].prepData.link) {
				window.location.href = cart.payments[0].prepData.link;
			} else if (cart.payments[0].state === 'processed') {
				window.location.href = `${window.location.pathname}/complete/${cart.orderUid}`;
			}
		}
		return () => {};
	}, [cart, cartCheckLoading, user.cartOrderUid]);

	useEffect(() => {
		if (user.uid && user.cartOrderUid) {
			dispatch(requestCartDraft(user.uid, user.cartOrderUid));
			dispatch(verifyCart());
		}
	}, [user.uid, user.cartOrderUid, dispatch]);

	useEffect(() => {
		if (orderDraft?.contactInfo?.billing) {
			setAcceptedTerms(!!orderDraft.acceptedTerms);
			setAcceptedSubscriptionTerms(!!orderDraft.acceptedSubscriptionTerms);
			setEnableAutomaticRenewal(!!orderDraft.enableAutomaticRenewal);
		}
	}, [orderDraft]);

	useEffect(() => {
		if (!user.cartOrderUid || !user.uid) {
			return;
		}
		if (locationState.products) {
			const lines = Object.keys(locationState.products)
				.map(productUid => ({
					productUid,
					qty: locationState.products[productUid],
					eventUid: locationState.event,
					locationUid: locationState.location
				}))
				.filter(p => p.qty > 0);
			console.log('adding new products...', lines);
			dispatch(addProductsToOrder(lines));
			navigate(location.pathname, { replace: true });
		}
		if (locationState.subscriptions) {
			const subscriptionsToAdd = Object.keys(locationState.subscriptions)
				.map(subscriptionTypeUid => ({
					qty: locationState.subscriptions[subscriptionTypeUid],
					subscriptionTypeUid
				}))
				.filter(p => p.qty > 0);
			console.log('adding new subscriptions...', subscriptionsToAdd);
			dispatch(addProductsToOrder(subscriptionsToAdd));
			navigate(location.pathname, { replace: true });
		}
		if (locationState.gifts) {
			console.log('adding new gift...', locationState.gifts);
			dispatch(
				addProductsToOrder(
					locationState.gifts.map(gift => ({
						qty: gift.qty,
						subscriptionTypeUid: gift.subscriptionTypeUid,
						gift: {
							ticketNumber: gift.ticketNumber,
							controlNumber: gift.controlNumber
						}
					}))
				)
			);
			navigate(location.pathname, { replace: true });
		}
	}, [locationState, user.cartOrderUid, user.uid, dispatch, navigate]);

	useEffect(() => {
		if (flags.printDiscountCodeOnPos && searchParams.has('st') && searchParams.has('dc')) {
			dispatch(addProductsToOrder([{ subscriptionTypeUid: searchParams.get('st'), qty: 1 }]));
			dispatch(applyCode(searchParams.get('dc')));
			navigate(location.pathname, { replace: true });
		}
	}, [flags.printDiscountCodeOnPos, searchParams, dispatch, location.pathname]);

	useEffect(() => {
		const amount = orderLines.reduce((accOrderLines, curOrderLine) => accOrderLines + curOrderLine.qty, 0);
		if (amount > 90) {
			dispatch(
				setCartError({
					type: 'alert',
					message: 'Du kan ikke have mere end 90 produkter i kurven på samme tid.'
				})
			);
		}
	}, [dispatch, orderLines]);

	async function handleTermAccept(event) {
		setAcceptedTerms(event.target.checked);
		await updateCartDraft(user.uid, user.cartOrderUid, { acceptedTerms: event.target.checked });
	}

	async function handleSubscriptionTermAccept(event) {
		setAcceptedSubscriptionTerms(event.target.checked);
		await updateCartDraft(user.uid, user.cartOrderUid, { acceptedSubscriptionTerms: event.target.checked });
	}

	async function handleAutomaticRenewalClick(event) {
		setEnableAutomaticRenewal(event.target.checked);
		await updateCartDraft(user.uid, user.cartOrderUid, { enableAutomaticRenewal: event.target.checked });
	}

	async function handleGoToCheckout() {
		await dispatch(verifyCart(true));
		if (googleTagManager) {
			tagManagerBeginCheckOut(orderLines);
		}
		setSearchParams({ co: user.cartOrderUid });
	}

	const runTagManagerViewCartOnce = useRef(false);

	useEffect(() => {
		if (!runTagManagerViewCartOnce.current && googleTagManager && orderLines.length > 0) {
			runTagManagerViewCartOnce.current = true;
			tagManagerViewCart(orderLines);
		}
	}, [googleTagManager, orderLines]);

	const containingSubscription = useMemo(() => {
		return orderLines?.some(orderLine => orderLine.productType === productType.SUBSCRIPTION);
	}, [orderLines]);

	const loadingOverlay = useMemo(() => {
		return orderLoading || allOrderLinesLoading || cartCheckLoading;
	}, [orderLoading, allOrderLinesLoading, cartCheckLoading]);

	return (
		<div className="w-full mx-auto pt-40">
			<div className="max-w-screen flex flex-col">
				<FuseScrollbars className="flex-grow overflow-x-auto">
					<div className={clsx(loadingOverlay && 'opacity-75')}>
						{cartError?.type === 'alert' && (
							<Alert severity="error" className="mb-20">
								<AlertTitle>{t('ERROR')}</AlertTitle>
								{cartError.message ?? t(cartError.messageKey)}
							</Alert>
						)}
						<CartContentOverview contentElementProps={contentElementProps} />
					</div>
					{((modules && modules.discountCodes) || (modules && modules.giftCards)) && <CartRedeemCode />}
					<Paper elevation={3} className="mb-48 mx-12 md:mx-0 pb-10">
						<Typography variant="h6" className="p-24">
							{t('CONFIRM')}
						</Typography>
						<div className="w-full flex flex-col md:flex-row">
							<div className={'w-full flex flex-col'}>
								{containingSubscription &&
									!flags.disableAutomaticRenewalButtonOnFrontend &&
									flags.pensoPaySubscription &&
									!(flags.subscriptionGiftTicket && cart?.totals?.total === 0) &&
									modules &&
									modules.subscriptions &&
									modules.subscriptions.automaticRenewal && (
										<FormControlLabel
											className="pl-24 whitespace-no-wrap"
											control={
												<Checkbox
													name="enableAutomaticRenewal"
													checked={enableAutomaticRenewal}
													onChange={handleAutomaticRenewalClick}
													color="primary"
												/>
											}
											label={
												<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
													{t('CART_AUTO_RENEWAL_ACCEPT')}
												</Typography>
											}
										/>
									)}
								{containingSubscription && flags.pensoPaySubscription && enableAutomaticRenewal && (
									<FormControlLabel
										className="pl-24 whitespace-no-wrap"
										control={
											<Checkbox
												name="acceptedSubscriptionTerms"
												checked={acceptedSubscriptionTerms}
												onChange={handleSubscriptionTermAccept}
												color="primary"
											/>
										}
										label={
											<Typography className="flex flex-col sm:flex-row items-start sm:items-center whitespace-nowrap">
												{t('I_AM_CONFIRMING')}
												<Button
													className={clsx(
														'normal-case p-0 xs:ml-0 sm:ml-4 underline',
														flags.addedColorOptionsForButtonsInCart && classes.termsButton
													)}
													onClick={() => setOpenSubscriptionTermsDialog(true)}
												>
													{t('CART_TERMS_OF_CONDITIONS_SUBSCRIPTION')}
												</Button>
											</Typography>
										}
									/>
								)}
								<FormControlLabel
									className="pl-24 whitespace-no-wrap"
									control={
										<Checkbox
											name="acceptedTerms"
											checked={acceptedTerms}
											onChange={handleTermAccept}
											color="primary"
										/>
									}
									label={
										<Typography className="flex flex-col sm:flex-row items-start sm:items-center whitespace-nowrap">
											{t('I_AM_CONFIRMING')}
											<Button
												className={clsx(
													'normal-case p-0 xs:ml-0 sm:ml-4 underline',
													flags.addedColorOptionsForButtonsInCart && classes.termsButton
												)}
												onClick={() => setOpenTermsDialog(true)}
											>
												{t('TERMS_OF_CONDITIONS')}
											</Button>
										</Typography>
									}
								/>
							</div>

							<div className="w-full flex flex-col justify-start md:justify-end items-start md:items-end">
								<div className="px-24">
									<Typography className="font-bold text-24" color="textSecondary">
										{t('TOTAL')}{' '}
										{moneyFormatter.format(cart && cart.totals ? cart.totals.total / 100 : 0)}
									</Typography>
								</div>
							</div>
						</div>
						<div className="flex flex-col md:flex-row justify-end pt-24 pb-6 px-16">
							<LoadingButton
								endIcon={<NavigateNext />}
								variant="contained"
								color="primary"
								className={'w-full md:w-256'}
								disabled={
									!cart ||
									!orderLines ||
									orderLines.length <= 0 ||
									!acceptedTerms ||
									allOrderLinesLoading ||
									orderLoading ||
									cartError ||
									(containingSubscription &&
										flags.pensoPaySubscription &&
										enableAutomaticRenewal &&
										!acceptedSubscriptionTerms)
								}
								onClick={handleGoToCheckout}
								loading={cart?.verifying}
							>
								{t('CART_CONTINUE')}
							</LoadingButton>
						</div>
					</Paper>
				</FuseScrollbars>

				<CartEmbeddedDialog
					page={'/handelsbetingelser'}
					embeddedId={'cartTermsDialog'}
					open={openTermsDialog}
					onClose={() => setOpenTermsDialog(false)}
				/>
				<CartEmbeddedDialog
					page={'/abonnementsbetingelser'}
					embeddedId={'cartTermsDialog'}
					open={openSubscriptionTermsDialog}
					onClose={() => setOpenSubscriptionTermsDialog(false)}
				/>
			</div>
		</div>
	);
}

export default CartContent;
