import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { verifyCustomer } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';

export default function CartInformationEmailField({ email, onResult }) {
	const [emailChecking, setEmailChecking] = useState(false);
	const [emailExisting, setEmailExisting] = useState(null);
	const [emailConfirmed, setEmailConfirmed] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		console.log('Running check...');
		if (!email) {
			setEmailChecking(false);
			setEmailExisting(null);
			setEmailConfirmed(null);
			onResult({ emailChecking: false, emailExisting: null, emailConfirmed: null });
			return;
		}
		const timeoutRef = setTimeout(() => {
			setEmailChecking(true);
			onResult({ emailChecking: true, emailExisting: null, emailConfirmed: null });
			verifyCustomer(email).then(verifiedResponse => {
				setEmailChecking(false);
				setEmailExisting(verifiedResponse.alreadyExists);
				setEmailConfirmed(verifiedResponse.emailVerified);
				onResult({
					emailChecking: false,
					emailExisting: verifiedResponse.alreadyExists,
					emailConfirmed: verifiedResponse.emailVerified
				});
			});
		}, 750);
		return () => clearTimeout(timeoutRef);
	}, [email]);

	if (!email) {
		return null;
	}

	return (
		((emailExisting !== null && emailExisting !== undefined) || emailChecking) && (
			<div className={'flex items-center gap-8 pl-10 mt-2'}>
				<div className={'flex items-center'}>
					{emailChecking && <CircularProgress size={12} className={'ml-4'} />}
					{!emailChecking && !emailExisting && (
						<Icon fontSize={'inherit'} color={'success'} className={'text-18'}>
							check
						</Icon>
					)}
					{!emailChecking && emailExisting && (
						<Icon fontSize={'inherit'} color={'error'} className={'-mr-4 text-18'}>
							close
						</Icon>
					)}
				</div>
				<Typography className={'whitespace-nowrap text-12'} color="textSecondary">
					{emailChecking && t('CART_CHECKING_EMAIL')}
					{!emailChecking && !emailExisting && t('CART_EMAIL_NOT_CREATED_BEFORE')}
					{!emailChecking && emailExisting && emailConfirmed && t('CART_EMAIL_ALREADY_CREATED')}
					{!emailChecking &&
						emailExisting &&
						!emailConfirmed &&
						t('CART_EMAIL_ALREADY_CREATED_NOT_CONFIRMED')}
				</Typography>
			</div>
		)
	);
}
