import React, { useEffect, useMemo, useState } from 'react';
import { Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { ticketsListener } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import SearchIcon from '@mui/icons-material/Search';
import AccountTable from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountTable';
import TicketStatus from '@ameroservices-platform/shared/ui-components/status/TicketStatus';
import AccountQR from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountQR';

export default function AccountTabTickets({ contentElementProps }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const user = useSelector(({ auth }) => auth.user);
	const tickets = useSelector(
		state => state.shared.user.tickets.filter(ticket => ticket.subscriptionUid === null) || []
	);
	const [searchText, setSearchText] = useState('');

	const fuseOptions = useMemo(
		() => ({
			keys: ['ticketNumber', 'nameOnTicketPDF', 'issuedTo.firstname', 'issuedTo.lastname'],
			threshold: 0
		}),
		[]
	);

	const fuse = useMemo(() => new Fuse(tickets, fuseOptions), [tickets]);

	const filteredTickets = useMemo(() => {
		if (searchText.trim()) {
			const results = fuse.search(searchText);
			return results.map(result => result.item);
		} else {
			return tickets;
		}
	}, [tickets, searchText, fuse]);

	useEffect(() => {
		if (user?.uid) {
			const unsubFuncEventTickets = dispatch(ticketsListener(user.uid));
			return () => {
				unsubFuncEventTickets();
			};
		}
		return () => {};
	}, [dispatch, user]);

	const columns = [
		{
			name: 'ticketNumber',
			label: t('TICKET_NUM'),
			options: { filter: true, sort: true }
		},
		{
			name: 'state',
			label: t('TICKET_STATUS'),
			options: {
				filter: true,
				sort: true,
				customBodyRender: value => <TicketStatus statusKey={value} />
			}
		},
		{
			name: 'nameOnTicketPDF',
			label: t('TYPE'),
			options: { filter: false, sort: false }
		},
		{
			name: 'issuedTo',
			label: t('ISSUED_TO'),
			options: {
				filter: false,
				sort: false,
				customBodyRender: value => `${value.firstname} ${value.lastname}`
			}
		},
		{
			name: 'ticketNumber',
			label: t('ACTIONS'),
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
				customBodyRender: (value, tableMeta) => (
					<div className={'flex justify-start md:justify-end items- pr-20'}>
						<div style={{ width: '40px', height: '40px', display: 'inline-block', padding: '8px' }}>
							<AccountQR
								contentElementProps={contentElementProps}
								ticketNumber={value}
								name={tableMeta.rowData[2]}
							/>
						</div>
					</div>
				)
			}
		}
	];

	return (
		<>
			<Grid container spacing={2} className={'mb-48'}>
				<Grid item xs={12}>
					<Paper className={'py-24 px-20'}>
						<div className={'flex items-center justify-between relative'}>
							<Typography variant="h5">{t('ACCOUNT_TAB_TICKETS')}</Typography>
							<div className="absolute right-0 top-0 transform -translate-y-1/2">
								<TextField
									variant="outlined"
									placeholder={t('SEARCH_FOR_TICKET')}
									value={searchText}
									onChange={e => setSearchText(e.target.value)}
									size="small"
									style={{ width: '170px' }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										)
									}}
								/>
							</div>
						</div>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper className={'px-2'}>
						<AccountTable
							data={filteredTickets}
							columns={columns}
							contentElementProps={contentElementProps}
						/>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
