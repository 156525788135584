export default {
	"DRAG_OR_CLICK_HERE": "Ziehen Sie Dateien oder klicken Sie hier",
	"CART_AUTOMATIC_RENEWAL_ACTIVATED_DESC": "Sie erhalten eine E-Mail, wenn Ihre Mitgliedschaft automatisch verlängert wird.",
	"PROCESSING_ORDER": "Wir bearbeiten Ihre Bestellung, bitte warten...",
	"PER_UNIT": "Preis pro Einheit",
	"REFUNDED": "Erstattet",
	"CART_TERMS_OF_CONDITIONS_SUBSCRIPTION": "die Abonnementbedingungen",
	"CART_AUTO_RENEWAL_ACCEPT": "Ich möchte die Mitgliedschaften vor Beginn jeder Periode automatisch verlängern",
	"ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD": "Kreditkarte ändern",
	"ACCOUNT_ACTIVATING": "Ihr Konto wird aktiviert. Bitte warten Sie.",
	"ERROR_UNKNOWN": "Ein unbekannter Fehler ist aufgetreten. Versuchen Sie es später erneut",
	"TYPE_EMAIL_UNDERNEATH": "Geben Sie Ihre E-Mail-Adresse ein, um mit der Erstellung Ihrer Mitgliedschaft zu beginnen.",
	"I_WILL_RENEW_SUBSCRIPTION": "Ich möchte das Abonnement zu Beginn jedes Zeitraums erneuern",
	"CART_LOADING_DESC": "Ihre Bestellung wird so schnell wie möglich bearbeitet. Sie erhalten eine Auftragsbestätigung per E-Mail",
	"NO_USER_FOUND_MESSAGE": "Sie scheinen kein Konto zu haben. Daher müssen Sie zunächst Ihr Konto erstellen.",
	"THE_GIFT_CARD": "Die Geschenkkarte",
	"CHOOSE_TICKET_AMOUNT_BEFORE": "Bitte wählen Sie die Anzahl der Tickets aus",
	"SEARCH_QUESTIONS": "Suchfragen...",
	"REMOVE_PRODUCT_FROM_CART": "Aus dem Warenkorb entfernen",
	"ORDER_NOTE_LABEL": "Hinweise zur Bestellung",
	"CART_PROCESSING_DESC_NO_ORDER": "Sie erhalten eine Auftragsbestätigung per E-Mail, wenn Ihre Bestellung bearbeitet wurde",
	"RENEW": "Erneuern",
	"END": "Ende",
	"ORDER_FAILED_TITLE": "Die Bestellung ist fehlgeschlagen",
	"THE_BALANCE_IS": "ist das Gleichgewicht",
	"MAIL": "E-Mail-Adresse",
	"CHOOSE_TICKETS": "Wählen Sie Ihre Tickets",
	"CHOOSE_TICKETS_BEFORE_BUY": "Wählen Sie zunächst Ihre Tickets aus",
	"PRODUCTS_HAS_DIFFERENT_TIMESLOTS": "Ausgewählte Tickets haben unterschiedliche Zeitfenster",
	"CART_SUCCESS_DESC": "Sie erhalten in Kürze eine Bestellbestätigung per E-Mail",
	"PHONE_INVALID_REMEMBER_COUNTRY_CODE": "Die Telefonnummer ist ungültig. Wenn die Telefonnummer nicht aus Dänemark stammt, denken Sie daran, vor der Nummer die Landesvorwahl (+xx) hinzuzufügen.",
	"EMAIL_ALREADY_EXISTS": "E-Mail existiert bereits. Melden Sie sich stattdessen an.",
	"AUTH_MISSING_EMAIL": "Fehlende E-Mail",
	"ACCOUNT_ADD_TO_APPLE_WALLET": "Zu Apple Wallet hinzufügen",
	"CHOOSE_DATE_BEFORE": "Bitte wählen Sie zuerst das Datum aus",
	"MORNING": "Morgen",
	"ACTIONS": "Aktionen",
	"MAX_ORDER": "Sie können maximal bestellen",
	"AWAITING_RENEWAL": "Warten auf Erneuerung",
	"OK_BUTTON": "Ja",
	"BEFORE_MIDDAY": "Am Vormittag",
	"MEMBERSHIP_RENEWAL_FROM": "Ihre Mitgliedschaft wird erneuert",
	"TOTAL": "Gesamt",
	"ERROR_PRODUCT": "Das Produkt an",
	"HOW_MANY": "Wie viele werden Sie besuchen?",
	"AUTH_EMAIL_ALREADY_IN_USE": "Die E-Mail wird bereits verwendet",
	"MEMBERSHIP_CARD": "Mitgliedskarte",
	"CHOOSE_TICKETS_BEFORE": "Bitte wählen Sie vorher die Anzahl der Tickets aus",
	"IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL": "Wenn Ihre E-Mail-Adresse in unserem System vorhanden ist, erhalten Sie in Kürze eine E-Mail, in der Sie Ihr Passwort zurücksetzen können",
	"ACCOUNT_NOT_FOUND": "Ihr Konto wurde nicht gefunden. Bitte versuchen Sie es später erneut",
	"IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS": "If you continue without selecting, the extras will not be added to your order.",
	"THANKS_FOR_ORDER": "Vielen Dank für Ihre Bestellung",
	"TO_BE_ABLE_TO_PURCHASE_THESE_PRODUCTS": "Um Extras zu Ihrer Bestellung hinzufügen zu können, müssen Sie Tickets mit demselben Zeitfenster auswählen.",
	"ACCOUNT_DOWNLOAD": "Download Bestellung",
	"YOUR_MEMBERSHIPS": "Ihre Mitgliedschaften",
	"CART_SAME_NAMES_AS_BILLING": "Gleicher Vor- und Nachname wie bei den Rechnungsinformationen",
	"FAQ_HEADER": "Häufig gestellte Fragen",
	"ACCOUNT_NEEDED_EMAIL_CONFIRMATION": "Bestätigung erforderlich",
	"TAX": "Steuer",
	"ALL_LOCATIONS_SELECTED": "Alle Standorte ausgewählt",
	"MAX_90_HEADER": "Maximal 90 Produkte",
	"ENTER_NEW_PASSWORD": "Geben Sie ein neues Passwort für den Benutzer mit der folgenden E-Mail-Adresse ein:",
	"ADD_TO_GOOGLE_WALLET_LOGO": "/assets/images/wallet/en-GB/en_add_to_google_wallet_add-wallet-badge.png",
	"SORRY": "Entschuldigung",
	"LAST_NAME_MIN_2_CHAR": "Der Nachname muss mindestens 2 Zeichen enthalten",
	"CREATE_MEMBERSHIP": "Mitgliedschaft erstellen",
	"ACCOUNT_EXISTS": "Haben Sie bereits ein Konto?",
	"ADD_TO_APPLE_WALLET_LOGO": "/assets/images/wallet/da-DK/US-UK_Add_to_Apple_Wallet.png",
	"CITY": "Stadt",
	"REMOVE_FROM_CART": "Möchten Sie dieses Produkt wirklich aus Ihrem Warenkorb entfernen?",
	"YOU_CAN_LOGIN": "Sie können sich jetzt anmelden",
	"MEMBERSHIP": "Mitgliedschaft",
	"CONTINUE_CREATING_SUBCRIPTION": "Hier können Sie mit der Mitgliedschaftsregistrierung fortfahren",
	"FULL_NAME": "Vollständiger Name",
	"CANCEL_BUTTON": "NEIN",
	"TYPE": "Typ",
	"PRODUCT": "Produkt",
	"CART_PAY_BUTTON": "ZAHLEN",
	"READ_MORE": "Mehr lesen",
	"LOGGED_IN_WITH_CORRECT_USER": "mit dem richtigen Benutzer angemeldet",
	"ERROR_EVENT": "Die Zeit ist da",
	"NEED_TO_BUY_MINIMUM_OF": "Sie müssen mindestens kaufen",
	"CHOOSE_DATE": "Datum auswählen",
	"BUY_TICKET_BUTTON": "TICKET KAUFEN",
	"TO_CREATE_MEMBERSHIP_YOU_NEED_A_USER": "Um eine Mitgliedschaft zu erstellen, müssen Sie ein Konto bei uns erstellt haben. Geben Sie daher zunächst Ihre E-Mail-Adresse ein, damit wir prüfen können, ob Sie bereits bei uns registriert sind.",
	"CLOSE": "Schließen",
	"PRICE_EA": "PREIS PR. EINHEIT",
	"TICKETS_LEFT": "Tickets übrig",
	"NEWSLETTER_SIGNUP": "Anmeldung für Newsletter",
	"ACCOUNT_ACTIVATED": "Dein Konto wurde aktiviert",
	"CART_CARDHOLDER": "Kartenhalter",
	"ALL_CATEGORIES_SELECTED": "Alle Kategorien ausgewählt",
	"DEACTIVATE_RENEWAL_EXPLAINER": "Durch die Deaktivierung der Verlängerung wird das Abonnement nicht verlängert. Sie müssen manuell bezahlen.",
	"PAYMENT": "Zahlung",
	"VALUE": "Wert",
	"NO_EVENTS": "Es gibt keine Übereinstimmungen mit Ihren Suchkriterien",
	"YOU_HAVE_ALREADY_ADDED_THE_MAX": "Sie haben bereits den Höchstbetrag hinzugefügt",
	"PASSWORD_LENGTH": "Das Passwort muss mindestens 6 Zeichen enthalten",
	"PHONE": "Telefon",
	"SELECT": "Wählen",
	"UPDATE_PROFILE": "Profil aktualisieren",
	"ADDRESS": "Adresse",
	"CONTINUE": "Weitermachen",
	"CORRECT_PASSWORD": "Korrektes Passwort, Sie werden jetzt angemeldet.",
	"TO": "Zu",
	"ORDER_FAILED_DESCRIPTION": "Die Bestellung wurde nicht abgeschlossen und das Geld wurde nicht abgebucht",
	"OF_THIS_PRODUCT": "dieses Produkts",
	"START": "Start",
	"RESEND_CONFIRMATION_EMAIL": "Bestätigungsmail erneut senden",
	"APPLY": "Anwenden",
	"GIFT_CARD": "Geschenkkarte",
	"PHONE_NR_MIN_2_CHAR_INT": "Die Telefonnummer muss zwischen 6 und 20 Zeichen lang sein",
	"GUEST": "Gast",
	"PAY": "Zahlen",
	"VISIT_TIME": "Wann möchten Sie anreisen?",
	"CONTINUE_WITHOUT_UPSELL": "Weiter ohne Extras",
	"NO_IMAGE": "Kein Bild ausgewählt",
	"PASSWORD": "Passwort",
	"NOT_FOUND": "Nicht gefunden",
	"PHONE_NUMERIC": "Die Telefonnummer muss numerisch sein",
	"CART_ERROR_DESC": "Bei der Bearbeitung Ihrer Bestellung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, wenn Sie Hilfe benötigen.",
	"WHICH_LOCATION": "Welchen Ort möchten Sie besuchen?",
	"SUBSCRIPTION_VALID_FROM": "Das Abonnement ist gültig ab",
	"PER_PERIOD": "Pro Periode",
	"MAIL_SENT": "Die Email wurde verschickt",
	"APPLY_GIFT_CARD_CODE": "Geschenkkarte anwenden",
	"EMAIL_IS_CONFIRMED": "Deine Email wurde bestätigt!",
	"DEACTIVATE_RENEWAL": "Deaktivieren Sie die automatische Verlängerung",
	"I_HAVE_READ_AND_ACCEPT": "Ich habe gelesen und akzeptiere",
	"AFTERNOON": "Nachmittag",
	"LOGOUT": "Ausloggen",
	"EXPIRES_ON": "Läuft aus am",
	"CHOOSE_QUANTITY": "Wählen Sie Tickets",
	"ADD_CREDITCARD": "Kreditkarte hinzufügen",
	"CART_ERROR_FIELDS": "Fehler",
	"LOGIN_AGAIN": "Nochmal anmelden",
	"SEND_CONFIRMATION_MAIL": "Bestätigungsmail senden",
	"AUTH_INVALID_EMAIL": "Die E-Mail ist ungültig",
	"ORDER_NUMBER": "Bestellnummer",
	"ERROR_RESETTING_PASSWORD": "Beim Zurücksetzen Ihres Passworts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
	"WE_ALREADY_SENT_EMAIL": "Wir haben Ihnen bereits eine E-Mail geschickt.",
	"CITY_MIN_2_CHAR": "Der Städtename muss mindestens 1 Zeichen enthalten",
	"DATO": "Datum",
	"AWAITING_PAYMENT": "Warten auf Zahlung",
	"AUTIMATIC_RENEWAL": "Ihre Mitgliedschaft ist nun auf automatische Verlängerung eingestellt",
	"GO_BACK_AND_CHANGE": "Gehen Sie zurück und entfernen Sie Tickets mit unterschiedlichen Zeitfenstern.",
	"LOADING_EVENT": "Veranstaltung wird geladen...",
	"VISIT_DATE": "Wann möchten Sie uns besuchen?",
	"PAGINATION_DISPLAY_ROWS": "von",
	"IS_CREATING": "Schafft",
	"SEARCH_FOR_TICKET": "Suche nach Ticket",
	"TRY_AGAIN": "Versuchen Sie es erneut",
	"THE_SUBCRIPTION": "das Abonnement",
	"POSTAL_CODE_NUMERIC": "Die Postleitzahl muss numerisch sein",
	"PRICE": "Preis",
	"CHANGE_IMAGE": "Bild ändern",
	"SEND_CONFIRMATION_EMAIL": "Bestätigungs-E-Mail senden",
	"CART_EMAIL_ALREADY_CREATED_NOT_CONFIRMED": "Sie sind bereits registriert, aber Ihre E-Mail ist noch nicht bestätigt",
	"CONTACT_DETAILS": "Geben Sie Ihre Kontaktdaten ein",
	"MORE_DATES": "Weitere Termine anzeigen",
	"CART_AUTOMATIC_RENEWAL_ACTIVATED": "Automatische Verlängerung ist aktiviert!",
	"DOES_NOT_EXIST_OR_EXPIRED": "existiert nicht oder ist abgelaufen",
	"EXISTING_ACCOUNT": "Bestehendes Konto",
	"AUTH_USER_DISABLED": "Der Benutzer ist gesperrt",
	"ACCOUNT_ADD_TO_GOOGLE_WALLET": "Zu Google Wallet hinzufügen",
	"FIRST_NAME": "Vorname",
	"ACCOUNT_INFO": "Kontoinformationen",
	"AUTOMATIC_RENEWAL_EXPLAINER": "Durch die Aktivierung der Verlängerung wird die Mitgliedschaft automatisch zu Beginn des nächsten Zeitraums erneuert",
	"CONFIRM": "Bestätigen",
	"AUTH_WRONG_PASSWORD": "Falsches Passwort",
	"EMAIL_CONFIRMED": "E-Mail bestätigt",
	"ORDER_FAILED_DESCRIPTION_2": "Bitte versuchen Sie es in Kürze erneut oder kontaktieren Sie uns, wenn das Problem weiterhin auftritt.",
	"QUANTITY": "Menge",
	"PRODUCTS_IN_SUBSCRIPTION": "Produkte im Abonnement",
	"ACTIVATE_ACCOUNT": "aktiviere deinen Account",
	"CART_DELETE_LINE": "Zeile löschen",
	"SIGN_UP_BUTTON": "Melden Sie sich an",
	"EMAIL_MUST_CONFIRMED_EXPLAINER": "Bevor Sie Ihre Mitgliedschaft erstellen können, müssen wir Ihre E-Mail-Adresse bestätigen",
	"POSTAL_CODE_LENGTH_INT": "Die Postleitzahl muss 3–8 Zeichen lang sein.",
	"UNKOWN_EVENT": "Unbekanntes Ereignis",
	"CONFIRM_EMAIL": "E-Mail bestätigen",
	"INACTIVE": "Inaktiv",
	"TO_ACTIVE_ACCOUNT": "Um Ihr Konto zu aktivieren, müssen Sie den Besitz der E-Mail bestätigen.",
	"CART_APPLY_CODE_ERROR": "Die von Ihnen eingegebene Geschenkkarte oder der Rabattcode konnte nicht angewendet werden. Dies kann daran liegen, dass er abgelaufen ist oder dass Sie bereits einen Rabattcode in Ihren Warenkorb gelegt haben.",
	"VALIDITY_PERIOD": "Gültigkeitszeitraum",
	"AUTH_WEAK_PASSWORD": "Das Passwort ist zu schwach. Verwenden Sie ein Passwort mit mehr als 8 Zeichen",
	"LOADING": "Wird geladen...",
	"NIGHT": "Nacht",
	"YOUR_ORDERS": "Deine Bestellungen",
	"CUSTOMER": "Kunde",
	"CART_BILLING_INFORMATION": "Abrechnungsdaten",
	"TERMS_OF_CONDITIONS": "die Allgemeinen Geschäftsbedingungen",
	"SELECT_DATE": "Wählen Sie das Datum aus, für das Sie eine Aktivität buchen möchten",
	"REMOVE_PRODUCT_NOT_ALLOWED": "Es ist nicht gestattet, dieses Produkt aus dem Warenkorb zu entfernen",
	"SEND_CODE": "Code senden",
	"COUNTRY": "Land *",
	"YES": "Ja",
	"CODE_IS_SENT_TO": "Der Code wurde an gesendet",
	"SOLD_OUT": "Ausverkauft",
	"MEMBERSHIP_TYPE_DOES_NOT_EXIST": "Der ausgewählte Mitgliedschaftstyp existiert nicht. Bitte versuchen Sie es mit einem anderen Typ.",
	"FIRST_NAMEFIRST_NAME_MIN_2_CHAR": "Der Vorname muss mindestens 2 Zeichen enthalten",
	"CARD": "Karte",
	"CHECK_SPAM": "Wenn Sie die Bestätigungs-E-Mail nicht innerhalb weniger Minuten erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner.",
	"PHONE_LENGTH": "Die Telefonnummer muss mindestens 8–11 Zeichen lang sein",
	"YOU_CAN_MIN_BUY": "Sie müssen mindestens kaufen",
	"YOU_HAVE_ALREADY_REMOVED_THE_MIN": "Sie haben den Mindestbetrag bereits entfernt",
	"ERROR_EMAIL_ALREADY_IN_USE": "Die E-Mail-Adresse wird bereits verwendet. Bitte versuchen Sie es mit einer anderen E-Mail",
	"SIGN_UP_STATUS": "Anmelden..",
	"AUTH_USER_NOT_FOUND": "Der Benutzer konnte nicht gefunden werden",
	"APPLY_DISCOUNT_CODE": "Aktionscode anwenden",
	"CODE": "Code",
	"LAST_NAME": "Familienname, Nachname",
	"YOUR_CART": "Ihr Warenkorb",
	"CART_EMAIL_NOT_CREATED_BEFORE": "Du bist nicht geschaffen und kannst weitermachen",
	"UPLOADING_PROFILE_PICTURE": "Profilbild hochladen",
	"PHONE_NR_MIN_2_CHAR": "Die Telefonnummer muss zwischen 8 und 11 Zeichen lang sein",
	"EMAIL_SENT": "Wir haben Ihnen gerade eine E-Mail gesendet, die Sie durch Anklicken des Buttons bestätigen müssen.",
	"PAGINATION_PREVIOUS": "Vorherige Seite",
	"FORGOT_PASSWORD": "PASSWORT VERGESSEN",
	"CART_ADD_GIFTCARD_OR_DISCOUNTCODE": "Weitere Geschenkkarte oder Rabattcode hinzufügen",
	"EVENT_TIME": "Veranstaltungszeit",
	"EDIT_UPSELL_OPTIONS": "Bearbeiten Sie zusätzliche Einkäufe",
	"CHANGING_MEMBERSHIP": "Wir ändern Ihre Mitgliedschaft, bitte warten...",
	"ENTER_PASSWORD": "Geben Sie ein Passwort für Ihr Konto ein",
	"FIRST_NAME_MIN_2_CHAR": "Der Vorname muss mindestens 2 Zeichen enthalten",
	"HI": "Hallo",
	"I_AM_CONFIRMING": "Ich bestätige",
	"MEMBERSHIP_NUMBER": "Abonnementnummer",
	"CONFIRMATION_MAIL_TO": "Eine Bestätigungsmail wird an gesendet",
	"CANCELLED": "Abgesagt",
	"TICKET_NUM": "Ticketnummer",
	"UPSELL_TITLE": "Extras für Ihre Bestellung",
	"ORDER_STATUS": "Bestellstatus",
	"TICKET": "Fahrkarte",
	"CHOOSE_CATEGORY": "Choose category",
	"FROM": "Aus",
	"CHARGE_IS_SEVEN_DAYS_BEFORE": "* Beachten Sie, dass bei automatischer Verlängerung der Mitgliedschaft die Belastung 7 Tage vor dem Ablaufdatum erfolgt",
	"APPLY_DISCOUNT_OR_GIFT_CARD_CODE": "Aktionscode oder Geschenkkarte anwenden",
	"GO_TO_PAYMENT": "Zum Checkout gehen",
	"EMAIL_CONFIRMNIG": "E-Mail wird bestätigt...",
	"CART_CARDHOLDER_FOR": "für",
	"YOUR_INFORMATIONS": "Ihre Informationen",
	"FORGOT_PASSWORD_QUESTIONMARK": "Passwort vergessen?",
	"ACCOUNT_EMAIL_NOT_CONFIRMED_YET": "Ihre E-Mail wurde noch nicht bestätigt. Überprüfen Sie Ihre E-Mails und klicken Sie auf den Link in der E-Mail.",
	"ACTIVATE": "aktivieren Sie",
	"PHONE_NUMBER": "Telefonnummer",
	"SEARCH": "Suchen",
	"CART_CONTINUE": "Weitermachen",
	"ON_THE_GIFT_CARD": "Auf der Geschenkkarte",
	"LOCATIONS": "Standorte",
	"BUY": "Kaufen",
	"CART_CHECKING_EMAIL": "E-Mails werden abgerufen...",
	"SEND_CONTROL_CODE": "Steuercode senden",
	"ISSUED_TO": "Lautend auf",
	"ADDRESS_MIN_2_CHAR": "Die Adresse muss mindestens 2 Zeichen enthalten",
	"DEACTIVATE": "Deaktivieren",
	"DOES_NOT_EXIST": "The page does not exist",
	"MY_PAGE": "Meine Seite",
	"BUY_TICKET_BUTTON_FREE": "Tickets reservieren",
	"YOUR_EMAIL_ADDRESS": "Your email",
	"DELETE": "Löschen",
	"REMOVE": "Entfernen",
	"CART_YOUR_ORDERNUMBER": "Ihre Bestellnummer lautet",
	"EMAIL_BEING_CONFIRMED": "Ihre E-Mail wird bestätigt. Bitte warten Sie.",
	"CART_EMAIL_ALREADY_CREATED": "You are already registered, enter your password below",
	"LINK_IS_DEAD": "Der Link, dem Sie gefolgt sind, scheint ungültig zu sein.",
	"CONFIRM_EMAIL_ERROR": "Die E-Mails stimmen nicht überein",
	"SELECT_FROM_LIST": "Wähle aus der Liste",
	"ACCOUNT_TAB_STATISTICS": "Statistiken",
	"CART_ADD_GIFTCARD": "Eine weitere Geschenkkarte hinzufügen",
	"NO_TIMES_AVAILABLE": "Keine Zeiten verfügbar",
	"BUY_ACTIVITIES": "Kaufen Sie zusätzliche Aktivitäten",
	"ORDER_DETAILS": "Bestelldetails",
	"AUTH_ERROR_TITLE": "Fehler",
	"CART_SUCCESS_TITLE": "Vielen Dank für Ihre Bestellung!",
	"LOGIN": "Anmeldung",
	"ACCOUNT_TAB_ORDERS": "Bestellungen",
	"CART_ERROR_TITLE": "Ihre Bestellung konnte nicht bearbeitet werden",
	"CATEGORIES": "Kategorien",
	"CONFIRMATION": "Bestätigung",
	"HAVE_NO_USER": "Sie haben keinen Benutzer?",
	"RESET_PASSWORD": "Passwort zurücksetzen",
	"ACCOUNT_YOUR_ACCOUNT": "Ihr Konto",
	"CREATE_USER": "Benutzer erstellen",
	"WELCOME": "Willkommen!",
	"ADD_ANOTHER_GIFT_CARD": "Fügen Sie eine weitere Geschenkkarte oder einen Aktionscode hinzu",
	"ACCOUNT_TAB_SUBSCRIPTIONS": "Mitgliedschaften",
	"NOT_VALID_EMAIL": "Ungültige E-Mail-Adresse",
	"CART_ERROR_MISSING_FIELDS": "Fehlende Vervollständigung",
	"THANKS_FOR_DETAILS": "Vielen Dank für Ihre Angaben",
	"ARE_YOU_NOT": "Bist du nicht",
	"AMOUNT_PEOPLE": "Wie viele Leute seid ihr?",
	"CHECK_GIFT_CARD": "Überprüfen Sie den Saldo auf Ihrer Geschenkkarte",
	"SELECTED_TIME": "Ausgewählte Zeit:",
	"YOUR_TICKETS": "Ihre Tickets",
	"ALREADY_EXISTS": "Die E-Mail ist bereits in unserem System vorhanden, wurde jedoch nicht für die Online-Nutzung aktiviert",
	"EMAIL": "Email",
	"EMAIL_DOES_NOT_EXIST": "ist nicht vorhanden",
	"CREATION_OF_MEMBERSHIP": "Gründung einer Mitgliedschaft",
	"IN_THE_FIELD": "in das Feld zur Bestätigung",
	"CARD_NUM": "Kartennummer",
	"ACCOUNT_MY_ACCOUNT": "Mein Konto",
	"HOW_MANY_ACTIVITIES": "Wählen Sie die Anzahl der Mitgliedschaften, für die Sie eine Aktivität buchen möchten",
	"MAX_90": "Sie können nicht mehr als 90 Produkte in Ihrem Warenkorb haben.",
	"ERROR": "Ein Fehler ist aufgetreten",
	"TOTAL_PRICE": "Gesamtpreis",
	"POSTAL_CODE": "Postleitzahl",
	"RELEASE_FILES_HERE": "Release-Dateien hier",
	"ACTIVE": "Aktiv",
	"SUBTOTAL": "Zwischensumme",
	"EMAIL_ERROR": "Dies ist keine gültige E-Mail",
	"COMPLETED": "Vollendet",
	"YOUR_MEMBERSHIP_CARDS": "Ihre Mitgliedskarten",
	"EXPIRES_SOON": "Läuft bald ab",
	"RENEWAL_WHEN_PAYMENT_CHARGED": "In automatic renewal, the charge will occur 7 days before the expiration date. In manual renewal, the charge will occur on the day you renew.",
	"AUTIMATIC_RENEWAL_EXPLAINER": "Durch die Aktivierung der Verlängerung wird die Mitgliedschaft automatisch zu Beginn des nächsten Zeitraums erneuert",
	"APP_TITLE": "listEventGroupDateSelector",
	"ACCOUNT_TAB_INFORMATION": "Information",
	"PASSWORD_CHANGED": "Passwort geändert",
	"CHANGING_PASSWORD": "Legen Sie Ihr Passwort fest – bitte warten.",
	"GO_BACK": "Gehen Sie zurück zu Ihrem Warenkorb",
	"BUY_TICKET": "Ticket kaufen",
	"ACCOUNT_SHOW_ORDER_DETAILS": "Bestelldetails anzeigen",
	"ORDER_NOTE_PLACEHOLDER": "Schreiben Sie hier Ihre Notizen",
	"TICKET_STATUS": "Ticketstatus",
	"WRITE": "schreiben",
	"CITY_LENGTH": "Der Ort muss mindestens 2 Zeichen lang sein",
	"PASSWORD_CHANGED_SUCCESSFULLY": "Ihr Passwort wurde geändert",
	"CHOOSE_LOCATION": "Ort wählen",
	"ACCOUNT_TAB_TICKETS": "Tickets",
	"THIS_DAY": "Tickets an diesem Tag.",
	"AUTH_OPERATION_NOT_ALLOWED": "Unbekannter Fehler",
	"LOGIN_INSTEAD": "Stattdessen anmelden?",
	"ACTIVATE_RENEWAL": "Aktivieren Sie die automatische Verlängerung",
	"CONFIRM_PASSWORD": "Bestätige das Passwort",
	"SUBSCRIPTION_GIFT_CARD": "Abonnement-Geschenkkarte",
	"PAGINATION_NEXT": "Nächste Seite",
	"ACTIVE_ACCOUNT_BY": "Dies können Sie tun, indem Sie dem unten stehenden Link folgen. Anschließend erhalten Sie eine E-Mail.",
	"BUY_TICKET_FREE": "Ticket reservieren",
	"TRY_NEW_CARD": "Versuchen Sie es mit einer neuen Karte",
	"AUTH_PASSWORDS_DO_NOT_MATCH": "Die Passwörter stimmen nicht überein",
	"YOU_MUST_CONFIRM_EMAIL": "Sie müssen Ihre E-Mail-Adresse bestätigen, um ein Konto zu erhalten.",
	"AMOUNT": "Menge",
	"CREATE_ACCOUNT": "Benutzerkonto erstellen",
	"CONFIRMATION_MAIL_TO_YOU": "Eine Bestätigungsmail wird Ihnen zugesandt",
	"CHOOSE_AMOUNT_PEOPLE": "Choose amount",
	"MISSING_ACCOUNT": "Fehlendes Konto",
	"PAGINATION_ROWS_PER_PAGE": "Zeilen pro Seite:",
	"YOU_CAN_CLOSE_THIS_WINDOW": "Sie können dieses Fenster nun schließen",
	"SUBSCRIPTION_VALID_FROM_FIRST_TIME": "Das Abonnement ist ab der ersten Nutzung gültig",
	"FINISH": "Beenden",
	"ALL_DATES_SELECTED": "Alle Termine ausgewählt",
	"CONFIRM_MAIL_TO_SHOW_ORDERS": "Bevor wir Ihren vorherigen {type} anzeigen können, müssen Sie Ihre E-Mail-Adresse bestätigen.",
	"PLEASE_WAIT": "Bitte warten",
	"ORDER_VISIT_TIMESTAMP": "Zeitpunkt des Besuchs",
	"POSTAL_CODE_LENGTH": "Die Postleitzahl muss 3–4 Zeichen lang sein.",
	"EMAIL_MUST_BE_CONFIRMED": "Ihre E-Mail muss bestätigt werden",
	"YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS": "Sie müssen eine Zeit oder Anzahl der Tickets auswählen.",
	"SELECT_ACTIVITIES": "Aktivitäten auswählen",
	"CHOOSE_TIME": "Wählen Sie die Zeit",
	"EVENING": "Abend",
	"MEMBERSHIP_IS_CREATED_ON_THIS_CUSTOMER": "Die Mitgliedschaft wird für diesen Kunden erstellt",
	"FAQ_SUBTITLE": "Wir sind hier um zu helfen",
	"ORDER": "Befehl",
	"CHECK_CODE": "Geschenkkarte prüfen",
	"YOU_CAN_MAX_BUY": "You can buy max",
	"CANCEL": "Stornieren",
	"ORDER_TIMESTAMP": "Bestellzeit",
	"NO_QUESTIONS": "Es wurden keine Fragen gefunden",
	"CART_PROCCESSING_TITLE": "Ihre Bestellung wird bearbeitet...",
	"ENTER_DETAILS": "Geben Sie Ihre Informationen ein, um ein Konto zu erstellen.",
	"PHONE_NR": "Telefonnummer",
}