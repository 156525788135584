import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useMemo, useState } from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { selectCart } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import orderState from '@ameroservices-platform/shared/enums/orderState';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function CartPaymentWindow() {
	const cart = useSelector(selectCart);
	const matches = useMediaQuery('(max-width:500px)');
	const [element, setElement] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const link = useMemo(() => {
		if (cart?.payments?.[0]?.prepData?.link && cart?.payments?.[0]?.prepData?.framed) {
			return cart.payments[0].prepData.link;
		}
		const mandate = cart?.payment?.mandates?.find(mandate => mandate?.prepData?.link && mandate?.prepData?.framed);
		if (mandate) {
			return mandate.prepData.link;
		}
		return null;
	}, [cart]);

	useEffect(() => {
		if ([orderState.PROCESSING, orderState.COMPLETED, orderState.ERROR].includes(cart?.state)) {
			setSearchParams({
				fo: cart?.orderUid
			});
		}
	}, [setSearchParams, cart]);

	useEffect(() => {
		if (!element) {
			return;
		}
		const func = args => {
			setLoaded(true);
		};
		element.addEventListener('load', func);
		return () => {
			element.removeEventListener('load', func);
		};
	}, [element]);

	function handleClose() {
		// TODO
	}

	return (
		<Dialog open maxWidth={'xs'} fullWidth fullScreen={matches}>
			<DialogContent className={'p-0 h-[700px]'}>
				{!loaded && (
					<Box
						className={'flex justify-center items-center h-full absolute top-0 right-0 w-full'}
						sx={{
							backgroundColor: 'background.paper'
						}}
					>
						<div className={'flex flex-1 flex-col items-center justify-center p-24'}>
							<Typography className="text-13 sm:text-20 mb-16" color="textSecondary">
								Opretter betaling...
							</Typography>
							<LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="secondary" />
						</div>
					</Box>
				)}
				<div className={'absolute top-0 right-0 m-10'}>
					<IconButton onClose={handleClose}>
						<Icon>close</Icon>
					</IconButton>
				</div>
				<iframe
					ref={setElement}
					id={'paymentFrame'}
					src={link}
					sandbox={'allow-forms allow-scripts allow-same-origin allow-modals'}
					title={'Betaling'}
					style={{ border: 'none' }}
					allowFullScreen
					width={'100%'}
					height={'100%'}
				/>
			</DialogContent>
		</Dialog>
	);
}
