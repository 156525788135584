import ContentPageConfig from './content/ContentPageConfig';
import FlexposPageConfig from './flexpos/FlexposPageConfig';
import CheckInPageConfig from './checkIn/CheckInPageConfig';
import MailConfirmedPageConfig from './content/types/user/mailConfirmed/MailConfirmedPageConfig';
import UserActivationPageConfig from './content/types/user/userActivation/UserActivationPageConfig';
import UserPhoneActivationPageConfig from './content/types/user/phoneActivation/UserPhoneActivationPageConfig';
import UserResetPasswordPageConfig from './content/types/user/resetPassword/UserResetPasswordPageConfig';
import CartExtraPagesConfig from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/CartExtraPagesConfig';

// ContentPageConfig should ALWAYS be last
const appsConfigs = [
	CartExtraPagesConfig,
	MailConfirmedPageConfig,
	UserActivationPageConfig,
	UserPhoneActivationPageConfig,
	UserResetPasswordPageConfig,
	FlexposPageConfig,
	CheckInPageConfig,
	ContentPageConfig
];

export default appsConfigs;
