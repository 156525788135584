import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import CmsContentElement from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/CmsContentElement';
import { CircularProgress, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { pageListener } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';

function CartEmbeddedDialog({ page, embeddedId, open, onClose }) {
	const [content, setContent] = useState(undefined);
	const [embeddedContentElementId, setEmbeddedContentElementId] = useState({});

	useEffect(() => {
		const termsPageListenerUnsubFunc = pageListener(page, data => {
			setContent(data);
			if (data?.content) {
				Object.entries(data.content).map(([key, value]) => {
					if (value.props.embeddedId && value.props.embeddedId === embeddedId) {
						setEmbeddedContentElementId(key);
					}
					return true;
				});
			}
		});
		return () => termsPageListenerUnsubFunc();
	}, [embeddedId, page]);

	return (
		<Dialog open={open} onClose={onClose} scroll="paper" fullWidth maxWidth={'md'}>
			{content === undefined && (
				<DialogContent dividers>
					<div className={'flex justify-center items-center py-128'}>
						<CircularProgress className="" color="primary" size="6rem" />
					</div>
				</DialogContent>
			)}
			{content === null && (
				<>
					<DialogTitle>Siden kunne ikke findes</DialogTitle>
					<DialogContent dividers>
						<Typography color="error">Kunne ikke finde siden {page}...</Typography>
					</DialogContent>
				</>
			)}
			{content?.content && embeddedContentElementId && (
				<DialogContent dividers>
					<CmsContentElement contentId={embeddedContentElementId} content={content.content} />
				</DialogContent>
			)}
			<DialogActions>
				<Button onClick={onClose} variant={'contained'}>
					Luk
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CartEmbeddedDialog;
