import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	orderLinesListener,
	ordersListener
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { orderStateTranslate } from '@ameroservices-platform/attraction-frontend/app/ui-components/states/orderState';
import AccountOrderDetailCard from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountOrderDetailCard';
import OrderStatus from '@ameroservices-platform/shared/ui-components/status/OrderStatus';

export default function AccountTabOrderDetails() {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const orderId = searchParams.get('orderId');
	const user = useSelector(({ auth }) => auth.user);
	const orders = useSelector(({ shared }) => shared.user.orders);
	const orderLines = useSelector(({ shared }) => shared.user.orderLines[orderId] || []);

	const selectedOrder = orders.find(order => order.orderUid === orderId);

	useEffect(() => {
		if (!orderId) {
			navigate('/account?tab=orders');
		}
	}, [orderId, navigate]);

	useEffect(() => {
		if (user?.uid && orderId) {
			const unsubUserOrders = dispatch(ordersListener(user.uid));
			const unsubUserOrderLines = dispatch(orderLinesListener(user.uid, orderId));
			return () => {
				unsubUserOrders();
				unsubUserOrderLines();
			};
		}
		return () => {};
	}, [dispatch, user, orderId]);

	return (
		<>
			<Grid container spacing={2} className={'mb-48'}>
				<Grid item xs={12}>
					<Paper className={'py-24 px-2 sm:px-20'}>
						<div className={'flex items-center justify-center flex-row xs:flex-col sm:justify-start'}>
							<IconButton onClick={() => navigate('/account?tab=orders')} className={'mr-8 p-0'}>
								<ArrowBackIcon fontSize={'large'} />
							</IconButton>
							<Typography variant="h5">{t('ORDER_DETAILS')}</Typography>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<AccountOrderDetailCard title={selectedOrder?.orderNumber} subtitle={t('ORDER_NUMBER')} />
						</Grid>
						<Grid item xs={12} sm={4}>
							<AccountOrderDetailCard
								title={
									selectedOrder?.createdAt ? moment(selectedOrder.createdAt).format('DD/MM/YYYY') : ''
								}
								subtitle={t('ORDER_TIMESTAMP')}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<AccountOrderDetailCard
								title={<OrderStatus statusKey={selectedOrder?.state} className={'text-24 pb-0 pt-0'} />}
								subtitle={t('ORDER_STATUS')}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Paper className={'py-24 px-2 sm:px-20'}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>{t('PRODUCT')}</TableCell>
									<TableCell>{t('QUANTITY')}</TableCell>
									<TableCell>{t('PER_UNIT')}</TableCell>
									<TableCell align="right">{t('TOTAL_PRICE')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orderLines.map((line, index) => (
									<TableRow key={index}>
										<TableCell>{line.name}</TableCell>
										<TableCell>{line.qty}</TableCell>
										<TableCell>{line.unitPrice/100} kr.</TableCell>
										<TableCell align="right">{line.linePrice/100} kr.</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell colSpan={3} style={{ borderBottom: 'none' }} />
									<TableCell>
										<div className="flex justify-between pt-6">
											<Typography>
												<b>{t('TOTAL')}</b>
											</Typography>
											<Typography>
												<b>{selectedOrder?.totals.total/100} kr.</b>
											</Typography>
										</div>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
