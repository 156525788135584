import { Control, Controller } from 'react-hook-form';
import React, { useMemo } from 'react';
import ValidationsType from '@ameroservices-platform/shared/ui-components/fields/ValidationsType';
import VariantType from '@ameroservices-platform/shared/ui-components/fields/VariantType';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormOptionType from '@ameroservices-platform/shared/ui-components/fields/FormOptionType';
import { Icon, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { hasPermission } from '@ameroservices-platform/shared/utility/index';

function SelectForm({
	name,
	label,
	control,
	options,
	className,
	validations,
	size,
	variant,
	fullWidth,
	required,
	disabled,
	multiple,
	loading,
	classes,
	onlyAmeroAdmin,
	helperText
}: {
	name: string;
	label?: string;
	control: Control;
	options: FormOptionType[];
	className?: string;
	validations?: ValidationsType;
	size?: 'small' | 'medium';
	variant?: VariantType;
	fullWidth?: boolean;
	required?: boolean;
	disabled?: boolean;
	multiple?: boolean;
	loading?: boolean;
	classes?: Record<string, unknown>;
	onlyAmeroAdmin?: boolean;
	helperText?: string;
}) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const user = useSelector(state => state.auth.user);
	const userRoleIsAmeroAdmin = useMemo(() => hasPermission(['ameroAdmin'], user.role), [user.role]);
	const rules = useMemo(() => {
		if (disabled) {
			return {};
		}
		const rules: ValidationsType = validations ? validations : {};
		if (required) {
			rules.required = 'Dette felt er påkrævet';
		}
		return rules;
	}, [validations, required, disabled]);
	const allowedOptions = useMemo<FormOptionType[]>(() => {
		if (!options) {
			return [];
		}
		return options;
	}, [options]);

	if (onlyAmeroAdmin && !userRoleIsAmeroAdmin) {
		return null;
	}

	return (
		<div className={'flex items-center gap-4 w-full'}>
			{onlyAmeroAdmin && (
				<Tooltip title="Kun muligt for amero admins">
					<Icon className={'mr-10'}>security</Icon>
				</Tooltip>
			)}
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field, fieldState: { invalid, error } }) => {
					return (
						<Autocomplete
							noOptionsText={'Ingen resultater'}
							loading={loading}
							loadingText={'Indlæser...'}
							multiple={multiple}
							className={clsx(className, 'w-full')}
							disabled={disabled}
							options={allowedOptions.map(option => option.value)}
							value={field.value || (multiple ? [] : null)}
							onChange={(event, newValue) => {
								field.onChange(newValue);
							}}
							size={size || 'medium'}
							getOptionLabel={option => {
								const optionFromOptions = allowedOptions.find(o => o.value === option);
								return option && optionFromOptions && optionFromOptions.label
									? optionFromOptions.label
									: 'Ukendt';
							}}
							renderInput={params => (
								<TextField
									{...params}
									helperText={params?.helperText ?? helperText}
									label={label ? label + (required ? ' *' : '') : undefined}
									variant={variant}
								/>
							)}
							openOnFocus
							classes={classes}
						/>
					);
				}}
			/>
		</div>
	);
}

export default SelectForm;
