import React, { lazy } from 'react';

const CartComplete = lazy(() => import('./complete/CartComplete'));

const CartExtraPagesConfig = {
	settings: {
		layout: {
			config: { hideNavigation: true }
		}
	},
	routes: [
		{
			path: '/cart/complete/:orderUid',
			element: <CartComplete />
		}
	]
};

export default CartExtraPagesConfig;
