import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';
import ProductNameCell from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/content/ProductNameCell';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import ProductAmountCell from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/content/ProductAmountCell';
import ProductTotalCell from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/content/ProductTotalCell';
import { getLocaleFromData } from '@ameroservices-platform/attraction-frontend/app/utilities';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { DeleteOutline } from '@mui/icons-material';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCartLoadingById } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { Skeleton } from '@mui/material';
import moment from 'moment-timezone';

function getEventTime(s, e) {
	const start = moment(s);
	const end = moment(e);
	if (start.clone().startOf('day').isSame(end.clone().startOf('day'))) {
		return `${start.format('dddd DD-MM-YYYY')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`;
	}
	return `${start.format('dddd DD-MM-YYYY, HH:mm')} - ${end.format('dddd DD-MM-YYYY, HH:mm')}`;
}

export default function CartOrderLine({ orderLine, classes, subscriptionPeriods, events, onOrderLineAmountChange }) {
	const flags = useFlags();
	const { t } = useTranslation();
	const finished = useSelector(state => selectCartLoadingById(state, 'finished'));
	const orderLineLoading = useSelector(state => selectCartLoadingById(state, orderLine.id));

	return (
		<>
			<TableRow key={orderLine.id}>
				<TableCell
					className={clsx(
						classes.tdLeft,
						((orderLine.followProducts && orderLine.followProducts.length > 0) ||
							(orderLine.discountCodeProducts && orderLine.discountCodeProducts.length > 0)) &&
							'no-border border-b-0'
					)}
				>
					<ProductNameCell
						orderLine={orderLine}
						events={events}
						multiLanguageFunctions={flags.multiLanguageFunctions}
						subscriptionPeriods={subscriptionPeriods}
					/>
				</TableCell>
				<Hidden only={['xs', 'sm', 'md']}>
					<TableCell
						align="center"
						className={clsx(
							((orderLine.followProducts && orderLine.followProducts.length > 0) ||
								(orderLine.discountCodeProducts && orderLine.discountCodeProducts.length > 0)) &&
								'no-border border-b-0'
						)}
					>
						<Typography className="font-medium" variant="subtitle1" color="textSecondary">
							{moneyFormatter.format(orderLine.unitPrice / 100)}
						</Typography>
					</TableCell>
				</Hidden>
				<TableCell
					className={clsx(
						((orderLine.followProducts && orderLine.followProducts.length > 0) ||
							(orderLine.discountCodeProducts && orderLine.discountCodeProducts.length > 0)) &&
							'no-border border-b-0'
					)}
					align="center"
				>
					{orderLine.lineType === 'followProduct' && !orderLine.allowCustomerToRemove ? (
						orderLine.qty
					) : (
						<>
							<ProductAmountCell
								classes={classes}
								finished={finished}
								orderLine={orderLine}
								onAmountChange={onOrderLineAmountChange}
								disabled={!!orderLine.giftValidation}
							/>
						</>
					)}
				</TableCell>
				<Hidden only={['md', 'lg', 'xl']}>
					<TableCell
						className={clsx(
							((orderLine.followProducts && orderLine.followProducts.length > 0) ||
								(orderLine.discountCodeProducts && orderLine.discountCodeProducts.length > 0)) &&
								'no-border border-b-0',
							'h-0'
						)}
						align="right"
					>
						<ProductTotalCell
							orderLine={orderLine}
							onAmountChange={onOrderLineAmountChange}
							finished={finished}
							moneyFormatter={moneyFormatter}
							loading={orderLineLoading}
							classes={classes}
						/>
					</TableCell>
				</Hidden>
				<Hidden only={['xs', 'sm']}>
					<TableCell
						align="right"
						className={clsx(
							((orderLine.followProducts && orderLine.followProducts.length > 0) ||
								(orderLine.discountCodeProducts && orderLine.discountCodeProducts.length > 0)) &&
								'no-border border-b-0'
						)}
					>
						<Typography className="font-medium" variant="subtitle1" color="textSecondary">
							{orderLineLoading ? (
								<Skeleton animation={'wave'} className={'w-76 py-4 -my-4 inline-block'} />
							) : (
								moneyFormatter.format(orderLine.linePrice / 100)
							)}
						</Typography>
					</TableCell>
				</Hidden>
			</TableRow>
			{orderLine.followProducts &&
				orderLine.followProducts.map((followProductOl, index) => {
					const notLast = index !== orderLine.followProducts.length - 1;
					return (
						<TableRow key={followProductOl.id} className="opacity-75">
							<TableCell
								className={`${classes.tdLeft} relative ${notLast ? 'no-border border-b-0' : ''}`}
								style={{ paddingTop: 0 }}
							>
								<div
									className="pt-28 sm:pt-16 mr-10 ml-10 border-gray border-dotted absolute top-0"
									style={{ borderWidth: '0 2px 0 0' }}
								/>
								<div className="flex">
									<div
										className="pl-16 mr-10 ml-10 border-gray border-dotted"
										style={{
											borderWidth: '2px 0 0 0',
											marginTop: '1.4rem'
										}}
									/>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{flags.multiLanguageFunctions
											? getLocaleFromData(followProductOl, 'name')
											: followProductOl.name}
									</Typography>
								</div>
								{notLast && (
									<div
										className="mr-10 ml-10 border-gray border-dotted absolute bottom-0"
										style={{
											borderWidth: '0 2px 0 0',
											marginBottom: '0.22rem',
											paddingBottom:
												flags.upsellModuleWithEvents && orderLine.eventUid ? '5.8rem' : '2.8rem'
										}}
									/>
								)}
								{followProductOl.eventUid && followProductOl.productType !== 'giftTicket' && (
									<div className={'pl-16 ml-20 lg:max-w-none md:max-w-200 max-w-128'}>
										<Typography
											className="font-medium truncate"
											variant="subtitle2"
											color="textSecondary"
										>
											{!flags.multiLanguageFunctions && (
												<>
													{events[followProductOl.eventUid]
														? events[followProductOl.eventUid].displayName ||
														  events[followProductOl.eventUid].name ||
														  t('UNKOWN_EVENT')
														: t('LOADING_EVENT')}
												</>
											)}
											{flags.multiLanguageFunctions && (
												<>
													{events[followProductOl.eventUid]
														? getLocaleFromData(
																events[followProductOl.eventUid],
																'displayName'
														  ) ||
														  getLocaleFromData(events[followProductOl.eventUid], 'name') ||
														  t('UNKOWN_EVENT')
														: t('LOADING_EVENT')}
												</>
											)}
										</Typography>
										{events[followProductOl.eventUid] &&
											(orderLine.showEventPeriodInCart === null ||
												orderLine.showEventPeriodInCart === undefined ||
												orderLine.showEventPeriodInCart === true) && (
												<Typography
													className="font-medium truncate"
													variant="subtitle2"
													color="textSecondary"
												>
													{getEventTime(
														events[followProductOl.eventUid].start,
														events[followProductOl.eventUid].end
													)}
												</Typography>
											)}
									</div>
								)}
							</TableCell>
							<Hidden only={['xs', 'sm', 'md']}>
								<TableCell
									align="center"
									style={{ paddingTop: 0 }}
									className={notLast ? 'no-border border-b-0' : ''}
								>
									{followProductOl.followProductOnePrLine && (
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{moneyFormatter.format(followProductOl.unitPrice / 100)}
										</Typography>
									)}
								</TableCell>
							</Hidden>
							<TableCell
								className={notLast ? 'no-border border-b-0' : ''}
								align="center"
								style={{ paddingTop: 0 }}
							>
								{followProductOl.followProductOnePrLine && (
									<>
										<ProductAmountCell
											classes={classes}
											finished={finished}
											onAmountChange={onOrderLineAmountChange}
											orderLine={followProductOl}
											followProduct
										/>
									</>
								)}
							</TableCell>
							<Hidden only={['md', 'lg', 'xl']}>
								<TableCell
									className={notLast ? 'no-border border-b-0' : ''}
									align="right"
									style={{ paddingTop: 0 }}
								>
									<div className="h-full flex flex-col">
										<Hidden only={['sm', 'md', 'lg', 'xl']}>
											<Tooltip title={t('REMOVE_PRODUCT_FROM_CART')}>
												<div className="flex-1 flex justify-end items-start">
													<Button
														size="small"
														className="p-0 min-w-0"
														onClick={() => onOrderLineAmountChange(orderLine, 0)}
														disabled={finished || !followProductOl.allowCustomerToRemove}
													>
														<DeleteOutline />
													</Button>
												</div>
											</Tooltip>
										</Hidden>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{orderLineLoading ? (
												<Skeleton
													animation={'wave'}
													className={'w-76 py-4 -my-4 inline-block'}
												/>
											) : (
												moneyFormatter.format(followProductOl.linePrice / 100)
											)}
										</Typography>
										<Hidden only={['sm', 'md', 'lg', 'xl']}>
											<div className="flex-1" />
										</Hidden>
									</div>
								</TableCell>
							</Hidden>
							<Hidden only={['xs', 'sm']}>
								<TableCell
									align="right"
									style={{ paddingTop: 0 }}
									className={notLast ? 'no-border border-b-0' : ''}
								>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{orderLineLoading ? (
											<Skeleton animation={'wave'} className={'w-76 py-4 -my-4 inline-block'} />
										) : (
											moneyFormatter.format(followProductOl.linePrice / 100)
										)}
									</Typography>
								</TableCell>
							</Hidden>
						</TableRow>
					);
				})}
			{orderLine.discountCodeProducts &&
				orderLine.discountCodeProducts.map((discountCodeOl, index) => {
					const notLast = index !== orderLine.discountCodeProducts.length - 1;
					return (
						<TableRow key={discountCodeOl.id} className="opacity-75">
							<TableCell
								className={`${classes.tdLeft} relative ${notLast ? 'no-border border-b-0' : ''}`}
								style={{ paddingTop: 0 }}
							>
								<div
									className="pt-28 sm:pt-16 mr-10 ml-10 border-gray border-dotted absolute top-0"
									style={{ borderWidth: '0 2px 0 0' }}
								/>
								<div className="flex">
									<div
										className="pl-16 mr-10 ml-10 border-gray border-dotted"
										style={{
											borderWidth: '2px 0 0 0',
											marginTop: '1.4rem'
										}}
									/>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{flags.multiLanguageFunctions
											? getLocaleFromData(discountCodeOl, 'name')
											: discountCodeOl.name}
									</Typography>
								</div>
								{notLast && (
									<div
										className="pb-28 mr-10 ml-10 border-gray border-dotted absolute bottom-0"
										style={{
											borderWidth: '0 2px 0 0',
											marginBottom: '0.22rem'
										}}
									/>
								)}
								{discountCodeOl.eventUid && discountCodeOl.productType !== 'giftTicket' && (
									<>
										<Typography className="font-medium" variant="subtitle2" color="textSecondary">
											{!flags.multiLanguageFunctions && (
												<>
													{events[discountCodeOl.eventUid]
														? events[discountCodeOl.eventUid].displayName ||
														  events[discountCodeOl.eventUid].name ||
														  t('UNKOWN_EVENT')
														: t('LOADING_EVENT')}
												</>
											)}
											{flags.multiLanguageFunctions && (
												<>
													{events[discountCodeOl.eventUid]
														? getLocaleFromData(
																events[discountCodeOl.eventUid],
																'displayName'
														  ) ||
														  getLocaleFromData(events[discountCodeOl.eventUid], 'name') ||
														  t('UNKOWN_EVENT')
														: t('LOADING_EVENT')}
												</>
											)}
										</Typography>
										{events[discountCodeOl.eventUid] &&
											(orderLine.showEventPeriodInCart === null ||
												orderLine.showEventPeriodInCart === undefined ||
												orderLine.showEventPeriodInCart === true) && (
												<Typography
													className="font-medium"
													variant="subtitle2"
													color="textSecondary"
												>
													{getEventTime(
														events[discountCodeOl.eventUid].start,
														events[discountCodeOl.eventUid].end
													)}
												</Typography>
											)}
									</>
								)}
							</TableCell>
							<Hidden only={['xs', 'sm', 'md']}>
								<TableCell
									align="center"
									style={{ paddingTop: 0 }}
									className={notLast ? 'no-border border-b-0' : ''}
								>
									{discountCodeOl.followProductOnePrLine && (
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{moneyFormatter.format(discountCodeOl.unitPrice / 100)}
										</Typography>
									)}
								</TableCell>
							</Hidden>
							<TableCell
								className={notLast ? 'no-border border-b-0' : ''}
								align="center"
								style={{ paddingTop: 0 }}
							>
								{discountCodeOl.followProductOnePrLine && (
									<>
										<ProductAmountCell
											classes={classes}
											finished={finished}
											onAmountChange={onOrderLineAmountChange}
											orderLine={discountCodeOl}
											followProduct
										/>
									</>
								)}
							</TableCell>
							<Hidden only={['md', 'lg', 'xl']}>
								<TableCell
									className={notLast ? 'no-border border-b-0' : ''}
									align="right"
									style={{ paddingTop: 0 }}
								>
									<div className="h-full flex flex-col">
										<Hidden only={['sm', 'md', 'lg', 'xl']}>
											<Tooltip title={t('REMOVE_PRODUCT_FROM_CART')}>
												<div className="flex-1 flex justify-end items-start">
													<Button
														size="small"
														className="p-0 min-w-0"
														onClick={() => onOrderLineAmountChange(orderLine, 0)}
														disabled={finished || !discountCodeOl.allowCustomerToRemove}
													>
														<DeleteOutline />
													</Button>
												</div>
											</Tooltip>
										</Hidden>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{orderLineLoading ? (
												<Skeleton
													animation={'wave'}
													className={'w-76 py-4 -my-4 inline-block'}
												/>
											) : (
												moneyFormatter.format(discountCodeOl.linePrice / 100)
											)}
										</Typography>
										<Hidden only={['sm', 'md', 'lg', 'xl']}>
											<div className="flex-1" />
										</Hidden>
									</div>
								</TableCell>
							</Hidden>
							<Hidden only={['xs', 'sm']}>
								<TableCell
									align="right"
									style={{ paddingTop: 0 }}
									className={notLast ? 'no-border border-b-0' : ''}
								>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{orderLineLoading ? (
											<Skeleton animation={'wave'} className={'w-76 py-4 -my-4 inline-block'} />
										) : (
											moneyFormatter.format(discountCodeOl.linePrice / 100)
										)}
									</Typography>
								</TableCell>
							</Hidden>
						</TableRow>
					);
				})}
		</>
	);
}
