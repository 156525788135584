import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { NavigateNext } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { Checkbox, FormControlLabel, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert, Autocomplete } from '@mui/material';
import {
	addProductsToOrder,
	requestOrderDraft,
	selectAllOrderLinesLoading,
	selectCustomerId,
	selectFinished,
	selectIdsLoading,
	selectOrder,
	selectOrderDraft,
	selectOrderDraftLoading,
	selectOrderId,
	selectOrderLines,
	selectOrderLoading,
	selectMaxError,
	submitOrder,
	updateOrderDraft,
	verifyOrder,
	setMaxError
} from '../../../../../../store/shared/cartSlice';
import CartContentOverviewOld from './CartContentOverviewOld';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
import LoadingButton from '../../../../../../ui-components/LoadingButton';
import StorageImage from '../../../../../../ui-components/StorageImage';
import { selectCountries, selectGoogleTagManager, selectLogos } from '../../../../../../store/shared/frontendSlice';
// eslint-disable-next-line import/no-cycle
import CartTermsDialog from './CartTermsDialog';
import CartContentOverviewWithFollowProduct from './CartContentOverviewWithFollowProduct';
import {
	tagManagerBeginCheckOut,
	tagManagerViewCart
} from '../../../../../../services/googleTagManagerService/googleTagManagerService';
import { useForm } from 'react-hook-form';

function CartContent(props) {
	const flags = useFlags();
	const dispatch = useDispatch();
	const location = useLocation();
	const orderId = useSelector(selectOrderId);
	const customerId = useSelector(selectCustomerId);
	const orderLoading = useSelector(selectOrderLoading);
	const idsLoading = useSelector(selectIdsLoading);
	const orderDraftLoading = useSelector(selectOrderDraftLoading);
	const allOrderLinesLoading = useSelector(selectAllOrderLinesLoading);
	const order = useSelector(selectOrder);
	const orderDraft = useSelector(selectOrderDraft);
	const orderLines = useSelector(selectOrderLines);
	const finished = useSelector(selectFinished);
	const maxError = useSelector(selectMaxError);
	const countries = useSelector(selectCountries);
	const googleTagManager = useSelector(selectGoogleTagManager);
	const user = useSelector(({ auth }) => auth.user);

	const {
		watch,
		setValue: setInForm,
		control,
		formState
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			postalCode: '',
			city: '',
			address: ''
		}
	});
	const form = watch();
	const { isValid: isFormValid } = formState;
	const logos = useSelector(selectLogos);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [newsletterOptIn, setNewsletterOptIn] = useState(false);
	const [countryCode, setCountryCode] = useState('DK');
	const formRef = useRef(null);

	const moneyFormatter = new Intl.NumberFormat('da-DK', {
		style: 'currency',
		currency: 'DKK',
		minimumFractionDigits: 2
	});

	const navigate = useNavigate();
	const runTagManagerViewCartOnce = useRef(false);

	useEffect(() => {
		if (!runTagManagerViewCartOnce.current && googleTagManager && orderLines.length > 0) {
			runTagManagerViewCartOnce.current = true;
			tagManagerViewCart(orderLines);
		}
	}, [googleTagManager, orderLines]);

	useEffect(() => {
		if (
			order &&
			order.state === 'pendingPayment' &&
			order.payments &&
			order.payments.length > 0 &&
			order.payments[0].prepData &&
			order.payments[0].prepData
		) {
			if (order.payments[0].prepData.state === 'pending' && order.payments[0].prepData.link) {
				window.location.href = order.payments[0].prepData.link;
			} else if (order.payments[0].state === 'processed') {
				window.location.href = `${window.location.pathname}/complete/${order.orderUid}`;
			}
		}
		return () => {};
	}, [order]);

	useEffect(() => {
		if (customerId && orderId) {
			dispatch(requestOrderDraft(customerId, orderId));
		}
	}, [customerId, orderId, dispatch]);

	useEffect(() => {
		if (orderDraft && orderDraft.contactInfo && orderDraft.contactInfo.billing) {
			const ci = orderDraft.contactInfo.billing;
			['firstname', 'lastname', 'email', 'phone', 'postalCode', 'city', 'address'].forEach(key => {
				if (ci[key]) {
					setInForm(key, ci[key]);
				}
			});
			if (flags.salesToOtherCountries && orderDraft.contactInfo.billing.countryCode) {
				setCountryCode(orderDraft.contactInfo.billing.countryCode);
			}
			setAcceptedTerms(orderDraft.acceptedTerms);
			setNewsletterOptIn(orderDraft.newsletterOptIn ? orderDraft.newsletterOptIn : false);
		}
	}, [orderDraft, setInForm, flags.salesToOtherCountries]);

	useEffect(() => {
		if (
			order &&
			orderLines &&
			!allOrderLinesLoading &&
			!orderLoading &&
			!idsLoading &&
			orderLines.length === 0 &&
			!location.products
		) {
			navigate('/');
		}
	}, [order, orderLines, allOrderLinesLoading, orderLoading, idsLoading, navigate, location.products]);

	useEffect(() => {
		if (!idsLoading && orderId && customerId) {
			dispatch(verifyOrder());
		}
	}, [dispatch, idsLoading, orderId, customerId]);

	useEffect(() => {
		if (orderId && customerId && location.products) {
			const prods = [];
			Object.keys(location.products).forEach(productKey => {
				prods.push({ id: productKey, qty: location.products[productKey] });
			});
			console.log('adding new products...', prods);
			dispatch(
				addProductsToOrder(
					prods.filter(p => p.qty > 0),
					location.event,
					location.location ? location.location : ''
				)
			);
			navigate(location.pathname, { replace: true });
		}
	}, [location, orderId, customerId, dispatch, navigate]);

	useEffect(() => {
		const amount = orderLines.reduce((accOrderLines, curOrderLine) => accOrderLines + curOrderLine.qty, 0);
		if (amount > 90) {
			dispatch(setMaxError(true));
		} else {
			dispatch(setMaxError(false));
		}
	}, [dispatch, orderLines]);

	function handleTermAccept(event) {
		setAcceptedTerms(event.target.checked);
		dispatch(updateOrderDraft(customerId, orderId, { acceptedTerms: event.target.checked }));
	}

	function handleNewsletterOptIn(event) {
		setNewsletterOptIn(event.target.checked);
		dispatch(updateOrderDraft(customerId, orderId, { newsletterOptIn: event.target.checked }));
	}

	function goToPayment() {
		if (googleTagManager) {
			tagManagerBeginCheckOut(orderLines);
		}
		dispatch(submitOrder());
	}

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	const isUserNotLoggedIn = user && (user.isAnonymous || !user.role || user.role.length === 0);

	return (
		<div className="max-w-screen flex flex-col">
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Hidden only={['lg', 'xl']}>
					<Link to="/">
						<div className="flex justify-center items-start py-16">
							<StorageImage alt="logo" className="w-2/6 max-h-full" src={logos.headerCartMobile} />
						</div>
					</Link>
				</Hidden>
				<div
					className={
						orderLoading || allOrderLinesLoading || idsLoading || orderDraftLoading ? 'opacity-75' : ''
					}
				>
					{maxError && (
						<Alert severity="error" className="mb-20">
							<strong>Maksimum 90 produkter</strong>
							<br />
							Du kan ikke have mere end 90 produkter i kurven på samme tid.
						</Alert>
					)}
					<CartContentOverviewWithFollowProduct />
				</div>
				<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
					<div className="p-24">
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="flex flex-col"
							onChange={() => {
								const d = {};
								Object.keys(form).forEach(key => {
									if (form[key]) {
										d[`contactInfo.billing.${key}`] = form[key];
									}
								});
								dispatch(updateOrderDraft(customerId, orderId, d));
							}}
						>
							<div className="flex">
								<Grid container spacing={0} className="sm:max-w-screen">
									<Grid item sm={12} sl={12}>
										<Typography variant="h6" className="">
											Indtast kontaktoplysninger
										</Typography>
									</Grid>
									<Grid item xs={6} sm={6}>
										<TextFieldFormsyAdapter
											control={control}
											id="firstname"
											className="mt-8 mb-16 pr-6"
											type="text"
											name="firstname"
											label="Fornavn"
											value={form.firstname}
											validations={{
												minLength: 2
											}}
											validationErrors={{
												minLength: 'Fornavn skal indeholde mindst 2 tegn'
											}}
											variant="standard"
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={6} sm={6}>
										<TextFieldFormsyAdapter
											control={control}
											id="lastname"
											className="mt-8 mb-16"
											type="text"
											name="lastname"
											label="Efternavn"
											value={form.lastname}
											validations={{
												minLength: 2
											}}
											validationErrors={{
												minLength: 'Efternavn skal indeholde mindst 2 tegn'
											}}
											variant="standard"
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextFieldFormsyAdapter
											control={control}
											id="address"
											className="mt-8 mb-16"
											type="text"
											name="address"
											label="Adresse"
											value={form.address}
											validations={{
												minLength: 2
											}}
											validationErrors={{
												minLength: 'Adressen skal indeholde mindst 2 tegn'
											}}
											variant="standard"
											fullWidth
											required
										/>
									</Grid>

									<Grid item xs={6} sm={6}>
										<TextFieldFormsyAdapter
											control={control}
											id="postalCode"
											className="mt-8 mb-16 pr-6"
											type="text"
											name="postalCode"
											label="Postnummer"
											value={form.postalCode}
											validations={
												!flags.salesToOtherCountries || countryCode === 'DK'
													? {
															minLength: 3,
															maxLength: 4
													  }
													: {
															minLength: 3,
															maxLength: 8
													  }
											}
											validationErrors={
												!flags.salesToOtherCountries || countryCode === 'DK'
													? {
															minLength: 'Postnummer skal indeholde 3-4 tegn',
															maxLength: 'Postnummer skal indeholde 3-4 tegn'
													  }
													: {
															minLength: 'Postnummer skal indeholde 3-8 tegn',
															maxLength: 'Postnummer skal indeholde 3-8 tegn'
													  }
											}
											variant="standard"
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={6} sm={6}>
										<TextFieldFormsyAdapter
											control={control}
											id="city"
											className="mt-8 mb-16"
											type="text"
											name="city"
											label="By"
											value={form.city}
											validations={{
												minLength: 1
											}}
											validationErrors={{
												minLength: 'Bynavn skal indeholde mindst 1 tegn'
											}}
											variant="standard"
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={6} sm={6}>
										<TextFieldFormsyAdapter
											control={control}
											id="email"
											className="mt-8 mb-16 pr-6"
											type="text"
											name="email"
											label="Email"
											value={form.email}
											validations={{
												isEmail: true
											}}
											validationErrors={{
												isEmail: 'This is not a valid email'
											}}
											variant="standard"
											fullWidth
											required
											enableTrim
										/>
									</Grid>
									<Grid item xs={6} sm={6}>
										<TextFieldFormsyAdapter
											control={control}
											id="phone"
											className="mt-8 mb-16"
											type="text"
											name="phone"
											label="Telefonnummer"
											value={form.phone}
											validations={
												!flags.salesToOtherCountries || countryCode === 'DK'
													? {
															minLength: 8,
															maxLength: 11
													  }
													: {
															minLength: 6,
															maxLength: 20
													  }
											}
											validationErrors={
												!flags.salesToOtherCountries || countryCode === 'DK'
													? {
															minLength: 'Telefonnummer skal være imellem 8-11 tegn',
															maxLength: 'Telefonnummer skal være imellem 8-11 tegn'
													  }
													: {
															minLength: 'Telefonnummer skal være imellem 6-20 tegn',
															maxLength: 'Telefonnummer skal være imellem 6-20 tegn'
													  }
											}
											variant="standard"
											fullWidth
											required
										/>
									</Grid>

									{flags.salesToOtherCountries && (
										<Grid item sm={12}>
											<Autocomplete
												className="mt-8 mb-16 w-full"
												fullWidth
												id="countryCode"
												name="countryCode"
												options={Object.keys(countries)}
												value={countryCode}
												onChange={async (event, newValue) => {
													setCountryCode(newValue);
													dispatch(
														updateOrderDraft(customerId, orderId, {
															[`contactInfo.billing.countryCode`]: newValue
														})
													);
												}}
												getOptionLabel={option => {
													if (!countries[option]) {
														return '-';
													}
													const { name } = countries[option];
													return name || '-';
												}}
												renderInput={params => <TextField {...params} label="Land *" />}
												openOnFocus
												required
											/>
										</Grid>
									)}

									{flags.emailSubscription && flags.showNewsletterOptInCart && isUserNotLoggedIn && (
										<FormControlLabel
											className="whitespace-no-wrap"
											control={
												<Checkbox
													name="newsletterOptIn"
													id="newsletterOptIn"
													checked={newsletterOptIn}
													onChange={handleNewsletterOptIn}
													color="primary"
												/>
											}
											label={
												<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
													Tilmeld nyhedsbrev
												</Typography>
											}
										/>
									)}
								</Grid>
								<Hidden only={['xs', 'sm']}>
									<Grid container className="pl-24">
										<Grid item sm={12} xl={12} className="m-auto">
											<StorageImage className="" src={props.data.image} alt="" />
										</Grid>
									</Grid>
								</Hidden>
							</div>
						</form>
					</div>
				</Paper>
				<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
					<Typography variant="h6" className="p-24">
						Bekræft
					</Typography>
					<div className="flex flex-col md:flex-row">
						<FormControlLabel
							className="pl-24 whitespace-no-wrap"
							control={
								<Checkbox
									name="acceptedTerms"
									checked={acceptedTerms}
									onChange={handleTermAccept}
									color="primary"
								/>
							}
							label={
								<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
									Jeg bekræfter{' '}
									<Button
										className="normal-case p-0 xs:ml-0 sm:ml-4 underline "
										onClick={handleClickOpen}
									>
										Handelsbetingelserne
									</Button>
								</Typography>
							}
						/>

						{/* BETALINGSTYPE-----------------------------------*/}
						{/* <div className="max-w-screen ml-6 px-36 pt-12"> */}
						{/*	<div className="pb-16 flex items-center justify-center"> */}
						{/*		<Icon color="action">payment</Icon> */}
						{/*		<Typography className="h2 mx-16" color="textSecondary"> */}
						{/*			Betalingstype */}
						{/*		</Typography> */}
						{/*	</div> */}
						{/*	<div className="flex justify-center"> */}
						{/*		<Button */}
						{/*			variant={paymentMethod === 'card' ? 'contained' : 'outlined'} */}
						{/*			color="primary" */}
						{/*			startIcon={<CreditCard />} */}
						{/*			disabled={(order && order.state === 'pendingPayment') || loadingState === 'next'} */}
						{/*			onClick={() => handlePaymentChange('card')} */}
						{/*		> */}
						{/*			Betalingskort */}
						{/*		</Button> */}

						{/*		<Button */}
						{/*			variant={paymentMethod === 'test' ? 'contained' : 'outlined'} */}
						{/*			color="primary" */}
						{/*			startIcon={<AssignmentLate />} */}
						{/*			disabled={(order && order.state === 'pendingPayment') || loadingState === 'next'} */}
						{/*			onClick={() => handlePaymentChange('test')} */}
						{/*		> */}
						{/*			Test */}
						{/*		</Button> */}
						{/*	</div> */}
						{/* </div> */}

						<div className="w-full flex flex-col justify-start md:justify-end items-start md:items-end">
							<div className="px-24">
								{/* <div> */}
								{/*	<Typography className="font-bold" color="textSecondary"> */}
								{/*		10 billetter, i alt: (pris for 10 billetter) */}
								{/*	</Typography> */}
								{/* </div> */}
							</div>

							<div
								className="px-24"
								// className={disabled ? '' : classes.tdLeft}
							>
								<Typography className="font-bold text-24" color="textSecondary">
									I alt {moneyFormatter.format(order && order.totals ? order.totals.total / 100 : 0)}{' '}
								</Typography>
							</div>
						</div>
					</div>
					<div className="flex flex-col md:flex-row justify-end pt-24 pb-6 px-16">
						{/* <LoadingButton */}
						{/*	title="Gå til kurv" */}
						{/*	startIcon={<NavigateBefore />} */}
						{/*	variant="contained" */}
						{/*	className="" */}
						{/*	color="primary" */}
						{/*	loading={} */}
						{/* /> */}
						<LoadingButton
							title="Gå til betaling"
							endIcon={<NavigateNext />}
							variant="contained"
							color="primary"
							classes={{ button: 'w-full md:w-256' }}
							disabled={
								!isFormValid ||
								!order ||
								!orderLines ||
								orderLines.length <= 0 ||
								!acceptedTerms ||
								allOrderLinesLoading ||
								orderLoading ||
								orderDraftLoading ||
								maxError ||
								(flags.salesToOtherCountries && !countryCode)
							}
							onClick={goToPayment}
							loading={finished}
						/>
					</div>
				</Paper>
			</FuseScrollbars>

			<CartTermsDialog open={open} handleClose={handleClose} />
		</div>
	);
}

export default CartContent;
