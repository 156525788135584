import CartContent from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/content/CartContent';
import { useSearchParams } from 'react-router-dom';
import CartCheckout from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/checkout/CartCheckout';
import CartComplete from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/complete/CartComplete';
import { useDispatch, useSelector } from 'react-redux';
import { cartCheck } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { useEffect } from 'react';

export default function Cart(props) {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const user = useSelector(({ auth }) => auth.user);

	if (user && !user.cartOrderUid) {
		return null;
	}

	if (searchParams.has('fo') || searchParams.get('fs') === 'true') {
		return <CartComplete {...props} />;
	}
	if (searchParams.has('co')) {
		return <CartCheckout {...props} />;
	}
	return <CartContent {...props} />;
}
