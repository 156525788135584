import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

const pageListener = (uri, callback) => {
	const db = firebaseService.getContentRootDB();
	return firebaseService.salesChannelListener(
		db.collection('pages').where('uri', '==', uri).where('deleted', '==', false),
		data => {
			const pages = data.map(page => {
				if (page.content && typeof page.content === 'string') {
					page.content = JSON.parse(page.content);
				}
				return page;
			});
			let pageToUse = pages.find(page => page.salesChannelUid === firebaseService.getSalesChannelId());
			if (pageToUse) {
				return callback(pageToUse);
			}
			pageToUse = pages.find(page => page.salesChannelUid === null);
			if (pageToUse) {
				return callback(pageToUse);
			}
			return callback(null);
		}
	);
};

const languagesListener = callback => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('languages')
		.where('deleted', '==', false)
		.onSnapshot(
			query => {
				const languages = [];
				query.forEach(doc => {
					const language = doc.data();
					languages.push({
						...language,
						id: doc.id
					});
				});
				if (languages.length === 0) {
					callback(null);
				} else if (languages.length > 1) {
					callback(languages);
				}
			},
			error => {
				console.log(error);
			}
		);
};

const pageContentListener = (pageid, callback) => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('pages')
		.doc(pageid)
		.collection('content')
		.orderBy('order', 'desc')
		.onSnapshot(
			query => {
				const content = [];
				query.forEach(doc => {
					const docData = doc.data();
					content.push({
						...docData,
						id: doc.id
					});
				});
				callback(content);
			},
			error => {
				console.log(error);
			}
		);
};

const eventGroupListener = (eventGroupId, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.doc(eventGroupId)
		.onSnapshot(
			q => {
				const eventGroup = q.data();
				if (eventGroup.content && typeof eventGroup.content === 'string') {
					eventGroup.content = JSON.parse(eventGroup.content);
				}
				if (eventGroup && eventGroup.state === 'active') {
					callback({
						...eventGroup,
						id: q.id
					});
				} else {
					callback(null);
				}
			},
			error => {
				console.log(error);
			}
		);
};

const eventGroupLanguageListener = (eventGroupId, languageIsoCode, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.doc(eventGroupId)
		.collection('languages')
		.where('deleted', '==', false)
		.where('language.isoCode', '==', languageIsoCode)
		.onSnapshot(
			query => {
				const eventGroupLanguages = [];
				query.forEach(doc => {
					const eventGroupLanguage = doc.data();
					if (eventGroupLanguage.content && typeof eventGroupLanguage.content === 'string') {
						eventGroupLanguage.content = JSON.parse(eventGroupLanguage.content);
					}
					eventGroupLanguages.push({
						...eventGroupLanguage,
						id: doc.id
					});
				});

				if (eventGroupLanguages.length === 0) {
					callback(null);
				} else if (eventGroupLanguages.length > 1) {
					eventGroupLanguages.forEach(eventGroupLanguage => {
						if (!eventGroupLanguage.deleted) {
							callback(eventGroupLanguage);
						}
					});
				} else {
					callback(eventGroupLanguages[0]);
				}
			},
			error => {
				console.log(error);
			}
		);
};

const contentListener = callback => {
	const db = firebaseService.getContentRootDB();
	return db.onSnapshot(
		query => {
			const content = query.data();
			callback(content);
		},
		error => {
			console.log(error);
		}
	);
};

const pageLanguageListener = (pageId, languageIsoCode, callback) => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('pages')
		.doc(pageId)
		.collection('languages')
		.where('deleted', '==', false)
		.where('language.isoCode', '==', languageIsoCode)
		.onSnapshot(
			query => {
				const pageLanguages = [];
				query.forEach(doc => {
					const pageLanguage = doc.data();
					if (pageLanguage.content && typeof pageLanguage.content === 'string') {
						pageLanguage.content = JSON.parse(pageLanguage.content);
					}
					pageLanguages.push({
						...pageLanguage,
						id: doc.id
					});
				});

				if (pageLanguages.length === 0) {
					callback(null);
				} else if (pageLanguages.length > 1) {
					pageLanguages.forEach(pageLanguage => {
						if (!pageLanguage.deleted) {
							callback(pageLanguage);
						}
					});
				} else {
					callback(pageLanguages[0]);
				}
			},
			error => {
				console.log(error);
			}
		);
};

const layoutElementListener = (layoutElementType, callback) => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('layoutElements')
		.where('deleted', '==', false)
		.where('type', '==', layoutElementType)
		.where('salesChannelUid', '==', firebaseService.getSalesChannelId() || null)
		.onSnapshot(query => {
			const layoutElements = [];
			query.forEach(doc => {
				const docData = doc.data();
				if (docData && docData.content && typeof docData.content === 'string') {
					docData.content = JSON.parse(docData.content);
				}
				layoutElements.push({
					...docData,
					id: doc.id
				});
			});

			callback(layoutElements[0] || null);
		});
};

const layoutElementLanguageListener = (layoutElementId, languageIsoCode, callback) => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('layoutElements')
		.doc(layoutElementId)
		.collection('languages')
		.where('deleted', '==', false)
		.where('language.isoCode', '==', languageIsoCode)
		.onSnapshot(
			query => {
				const layoutElementLanguages = [];
				query.forEach(doc => {
					const layoutElementLanguage = doc.data();
					if (layoutElementLanguage.content && typeof layoutElementLanguage.content === 'string') {
						layoutElementLanguage.content = JSON.parse(layoutElementLanguage.content);
					}
					layoutElementLanguages.push({
						...layoutElementLanguage,
						id: doc.id
					});
				});

				callback(layoutElementLanguages[0] || null);
			},
			error => {
				console.log(error);
			}
		);
};

export {
	pageListener,
	pageContentListener,
	eventGroupListener,
	eventGroupLanguageListener,
	contentListener,
	pageLanguageListener,
	languagesListener,
	layoutElementListener,
	layoutElementLanguageListener
};
