import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { Add, Remove } from '@mui/icons-material';
import _ from 'lodash';
import {
	eventGroupsListener,
	eventGroupSummariesListener,
	selectEventGroups,
	selectEventGroupSummaries,
	selectEvents,
	selectSelectedUpsellEvents,
	selectSelectedUpsellProducts,
	setSelectedUpsellEvents,
	setSelectedUpsellProducts,
	updateUpsell
} from '../../../../../../../store/shared/cartSlice';
import LoadingButton from '../../../../../../../ui-components/LoadingButton';
import orderLineUpsellState from './orderLineUpsellState';
import { getLocaleFromData } from '../../../../../../../utilities';
import { findPricesForProduct } from '@ameroservices-platform/shared/utility/index';
import CmsContentElement from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/CmsContentElement';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import makeStyles from '@mui/styles/makeStyles';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import { selectCartOrderLines } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});
export default function UpsellModule({ forceOpen, onClose, contentElementProps }) {
	const dispatch = useDispatch();
	const cartOrderLines = useSelector(selectCartOrderLines);
	const [productAmount, setProductAmount] = useState({});
	const [loading, setLoading] = useState(false);
	const eventGroups = useSelector(selectEventGroups);
	const events = useSelector(selectEvents);
	const selectedUpsellEvents = useSelector(selectSelectedUpsellEvents);
	const selectedUpsellProducts = useSelector(selectSelectedUpsellProducts);
	const eventGroupSummaries = useSelector(selectEventGroupSummaries);
	const [missingError, setMissingError] = useState(null);
	const { t } = useTranslation();
	const flags = useFlags();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		upsellDialog: {
			backgroundColor: `${getColor(
				contentElementProps.upsellBackgroundColor,
				contentElementProps.upsellCustomBackgroundColor,
				mergedTheme
			)}`
		}
	}));

	const classes = useStyles();

	useEffect(() => {
		const newProductAmount = {};
		cartOrderLines.forEach(orderLine => {
			if (orderLine.upsellProducts) {
				newProductAmount[orderLine.id] = {};
				Object.values(orderLine.upsellProducts)
					.filter(upsellProduct => upsellProduct.state === orderLineUpsellState.ACCEPTED)
					.forEach(upsellProduct => {
						newProductAmount[orderLine.id][upsellProduct.followProduct.id] = upsellProduct.amount || 0;
					});
			}
		});
		setProductAmount(newProductAmount);
	}, [cartOrderLines]);

	const orderLinesWithUpsell = useMemo(() => {
		return cartOrderLines.filter(orderLine => orderLine.upsellProducts);
	}, [cartOrderLines]);

	const open = useMemo(() => {
		return (
			forceOpen ||
			orderLinesWithUpsell.some(orderLine =>
				Object.values(orderLine.upsellProducts).some(
					upsellProduct => upsellProduct.state === orderLineUpsellState.WAITING
				)
			)
		);
	}, [orderLinesWithUpsell, forceOpen]);

	function handleRemoveProduct(orderLine, upsellProduct) {
		const { followProduct } = upsellProduct;
		setProductAmount(prevState => {
			const clone = _.cloneDeep(prevState);
			if (!clone[orderLine.id]) {
				clone[orderLine.id] = {};
			}
			clone[orderLine.id][followProduct.id] = (clone[orderLine.id][followProduct.id] || 0) - 1;
			return clone;
		});
	}

	function handleAddProduct(orderLine, upsellProduct) {
		const { followProduct } = upsellProduct;
		setProductAmount(prevState => {
			const clone = _.cloneDeep(prevState);
			if (!clone[orderLine.id]) {
				clone[orderLine.id] = {};
			}
			clone[orderLine.id][followProduct.id] = (clone[orderLine.id][followProduct.id] || 0) + 1;
			return clone;
		});
	}

	const eventGroupUids = useMemo(() => {
		if (!flags.upsellModuleWithEvents) {
			return null;
		}
		return orderLinesWithUpsell.reduce(
			(acc, orderLine) =>
				acc.concat(
					Object.entries(orderLine.upsellProducts).reduce((acc, [upsellProductUid, upsellProduct]) => {
						if (upsellProduct?.product?.eventGroupUids) {
							acc.push(...upsellProduct?.product?.eventGroupUids);
						}
						return acc;
					}, [])
				),
			[]
		);
	}, [orderLinesWithUpsell, flags.upsellModuleWithEvents]);

	function getUpsellList(accept = [], deny = []) {
		const counter = {};
		Object.entries(selectedUpsellProducts).forEach(([eventGroupUid, productAmounts]) => {
			Object.entries(productAmounts).forEach(([followProductUid, amount]) => {
				const orderLines = orderLinesWithUpsell.filter(
					orderLine => !!orderLine.upsellProducts?.[followProductUid]
				);
				orderLines.forEach(orderLine => {
					if (orderLine.upsellProducts?.[followProductUid]) {
						const orderLineAmount = Math.min(
							amount - (counter?.[eventGroupUid]?.[followProductUid] || 0) || 0,
							orderLine.qty
						);
						if (orderLineAmount > 0 && selectedUpsellEvents[eventGroupUid]) {
							if (!counter[eventGroupUid]) {
								counter[eventGroupUid] = {};
							}
							if (!counter[eventGroupUid][followProductUid]) {
								counter[eventGroupUid][followProductUid] = 0;
							}
							counter[eventGroupUid][followProductUid] += orderLineAmount;
							const accepted = accept.find(
								a => a.orderLineUid === orderLine.id && a.followProductUid === followProductUid
							);
							// Add to existing accept or create new
							if (accepted) {
								accepted.amount += orderLineAmount;
								if (!accepted.events) {
									accepted.events = {};
								}
								accepted.events[eventGroupUid] = {
									eventUid: selectedUpsellEvents[eventGroupUid],
									amount: orderLineAmount
								};
							} else {
								accept.push({
									orderLineUid: orderLine.id,
									followProductUid,
									amount: orderLineAmount,
									events: {
										[eventGroupUid]: {
											eventUid: selectedUpsellEvents[eventGroupUid],
											amount: orderLineAmount
										}
									}
								});
							}
						} else if (
							!accept.some(
								a => a.orderLineUid === orderLine.id && a.followProductUid === followProductUid
							) &&
							!deny.some(a => a.orderLineUid === orderLine.id && a.followProductUid === followProductUid)
						) {
							// Do not add to deny if it is already accepted or denied
							deny.push({
								orderLineUid: orderLine.id,
								followProductUid
							});
						}
					}
				});
			});
		});
		orderLinesWithUpsell.forEach(orderLine =>
			Object.values(orderLine.upsellProducts).forEach(upsellProduct => {
				if (
					!accept.some(
						a => a.orderLineUid === orderLine.id && a.followProductUid === upsellProduct.followProduct.id
					) &&
					!deny.some(
						a => a.orderLineUid === orderLine.id && a.followProductUid === upsellProduct.followProduct.id
					)
				) {
					deny.push({
						orderLineUid: orderLine.id,
						followProductUid: upsellProduct.followProduct.id
					});
				}
			})
		);
		return { accept, deny };
	}

	async function handleClose(result) {
		setLoading(true);
		let accept = [];
		let deny = [];
		if (flags.upsellModuleWithEvents && eventGroupUids.length > 0) {
			// Checking if the user has selected both timeslot and amount of products for each upsell, but only if one of them is set
			const errors = [];
			orderLinesWithUpsell.forEach(orderLine => {
				Object.values(orderLine.upsellProducts).forEach(upsellProduct => {
					const { product, followProduct } = upsellProduct;
					if (!product.eventGroupUids) {
						return false;
					}
					product.eventGroupUids.forEach(eventGroupUid => {
						if (
							(selectedUpsellEvents[eventGroupUid] ||
								selectedUpsellProducts[eventGroupUid]?.[followProduct.id]) &&
							!(
								selectedUpsellEvents[eventGroupUid] &&
								selectedUpsellProducts[eventGroupUid]?.[followProduct.id]
							)
						) {
							errors.push({
								eventGroupUid,
								type: selectedUpsellEvents[eventGroupUid] ? 'product' : 'event'
							});
							return true;
						}
					});
				});
			});
			if (!missingError && errors.length > 0) {
				setLoading(false);
				setMissingError(errors);
				return;
			}
			accept = getUpsellList()?.accept || [];
			// Make a clone of accept list, so we can add to it without changing the original
			deny = getUpsellList(_.cloneDeep(accept))?.deny || [];
		} else {
			if (result) {
				Object.entries(productAmount).forEach(([orderLineUid, productAmounts]) => {
					Object.entries(productAmounts).forEach(([followProductUid, amount]) => {
						if (amount > 0) {
							accept.push({
								orderLineUid,
								followProductUid,
								amount
							});
						} else {
							deny.push({
								orderLineUid,
								followProductUid
							});
						}
					});
				});
			}
			orderLinesWithUpsell.forEach(orderLine =>
				Object.values(orderLine.upsellProducts)
					.filter(
						upsellProduct =>
							upsellProduct.state === orderLineUpsellState.WAITING &&
							!accept.some(
								a =>
									a.followProductUid === upsellProduct.followProduct.id &&
									a.orderLineUid === orderLine.id
							) &&
							!deny.some(
								a =>
									a.followProductUid === upsellProduct.followProduct.id &&
									a.orderLineUid === orderLine.id
							)
					)
					.forEach(upsellProduct => {
						deny.push({
							orderLineUid: orderLine.id,
							followProductUid: upsellProduct.followProduct.id
						});
					})
			);
		}
		await dispatch(updateUpsell(accept, deny));
		setLoading(false);
		onClose();
	}

	useEffect(() => {
		if (!flags.upsellModuleWithEvents || eventGroupUids.length <= 0) {
			return;
		}
		const unsubFuncsForEventGroups = dispatch(eventGroupsListener(eventGroupUids));
		const unsubFuncsForSummaries = dispatch(eventGroupSummariesListener(eventGroupUids));
		return () => {
			unsubFuncsForEventGroups?.forEach(unsub => unsub());
			unsubFuncsForSummaries?.forEach(unsub => unsub());
		};
	}, [eventGroupUids, flags.upsellModuleWithEvents]);

	const upsellProductsByEventGroup = useMemo(() => {
		if (!flags.upsellModuleWithEvents) {
			return null;
		}
		const eventGroupOrderLines = {};
		orderLinesWithUpsell.forEach(orderLine => {
			Object.entries(orderLine.upsellProducts).forEach(([productUid, upsellProduct]) => {
				if (upsellProduct?.product?.eventGroupUids) {
					upsellProduct?.product?.eventGroupUids.forEach(eventGroupUid => {
						if (!eventGroupOrderLines[eventGroupUid]) {
							eventGroupOrderLines[eventGroupUid] = [];
						}
						if (!eventGroupOrderLines[eventGroupUid].some(p => p.id === productUid)) {
							eventGroupOrderLines[eventGroupUid].push({
								id: productUid,
								orderLineUid: orderLine.id,
								followProductUd: upsellProduct.followProduct.id,
								...upsellProduct.product
							});
						}
					});
				}
			});
		});
		return eventGroupOrderLines;
	}, [orderLinesWithUpsell, flags.upsellModuleWithEvents]);

	const selectedProductsIsNotSameTime = useMemo(() => {
		return (
			flags.upsellModuleWithEvents &&
			!orderLinesWithUpsell.every(e => !e.eventUid || e.eventUid === orderLinesWithUpsell?.[0].eventUid)
		);
	}, [orderLinesWithUpsell, flags.upsellModuleWithEvents]);

	const selectedDate = useMemo(() => {
		if (!flags.upsellModuleWithEvents) {
			return null;
		}
		if (!events?.[orderLinesWithUpsell?.[0]?.eventUid]?.start) {
			return null;
		}
		return moment(events?.[orderLinesWithUpsell?.[0]?.eventUid]?.start);
	}, [orderLinesWithUpsell, events, flags.upsellModuleWithEvents]);

	const sortedEventGroups = useMemo(() => {
		if (!flags.upsellModuleWithEvents) {
			return null;
		}
		let _eventGroups = Object.values(eventGroups);
		_eventGroups = _eventGroups.filter(eventGroup => {
			if (!selectedDate || !eventGroupSummaries) {
				return false;
			}
			const groupSummaries = eventGroupSummaries[eventGroup?.id];
			if (!groupSummaries) {
				return false;
			}
			const groupSummary = groupSummaries.find(summary => summary.month === selectedDate.format('M-YYYY'));
			if (!groupSummary) {
				return false;
			}
			const times = groupSummary.dates?.[selectedDate.date()]?.times;
			if (!times) {
				return false;
			}
			return Object.entries(times).length > 0;
		});
		_eventGroups.sort((a, b) => {
			if (!contentElementProps?.attributeGroupUid) {
				return a.order - b.order;
			}
			const attributeA = a?.attributes?.[contentElementProps?.attributeGroupUid]?.[0] || '';
			const attributeB = b?.attributes?.[contentElementProps?.attributeGroupUid]?.[0] || '';
			if (attributeA === attributeB) {
				return a.order - b.order;
			}
			return attributeA.localeCompare(attributeB);
		});
		return _eventGroups;
	}, [eventGroups, contentElementProps, flags.upsellModuleWithEvents, eventGroupSummaries, selectedDate]);

	useEffect(() => {
		if (!flags.upsellModuleWithEvents) {
			return;
		}
		const selectedUpsellProducts = {};
		const selectedUpsellEvents = {};
		orderLinesWithUpsell.forEach(orderLine => {
			Object.entries(orderLine.upsellProducts).forEach(([followProductUid, upsellProduct]) => {
				if (upsellProduct.events) {
					Object.entries(upsellProduct.events).forEach(([eventGroupUid, { amount, eventUid }]) => {
						selectedUpsellProducts[eventGroupUid] = selectedUpsellProducts[eventGroupUid] || {};
						selectedUpsellProducts[eventGroupUid][followProductUid] =
							(selectedUpsellProducts[eventGroupUid][followProductUid] || 0) + amount;
						if (eventUid) {
							selectedUpsellEvents[eventGroupUid] = eventUid;
						}
					});
				}
			});
		});
		dispatch(setSelectedUpsellProducts(selectedUpsellProducts));
		dispatch(setSelectedUpsellEvents(selectedUpsellEvents));
	}, [orderLinesWithUpsell, flags.upsellModuleWithEvents]);

	return (
		<Dialog
			onClose={() => handleClose(false)}
			maxWidth="xs"
			fullWidth
			open={!!open}
			classes={{ paper: classes.upsellDialog }}
		>
			<DialogTitle className="text-center mr-20">{t('UPSELL_TITLE')}</DialogTitle>
			<IconButton
				className="absolute"
				style={{ top: '0.4rem', right: '0.4rem' }}
				onClick={() => handleClose(false)}
			>
				<Icon>close</Icon>
			</IconButton>
			<DialogContent>
				<div className="flex flex-col">
					{flags.upsellModuleWithEvents && eventGroupUids.length > 0 && (
						<div>
							{selectedProductsIsNotSameTime && (
								<Alert severity={'error'} className={'mb-20'} variant={'filled'}>
									<AlertTitle>{t('PRODUCTS_HAS_DIFFERENT_TIMESLOTS')}</AlertTitle>
									{t('TO_BE_ABLE_TO_PURCHASE_THESE_PRODUCTS')}
									<br />
									<br />
									{t('GO_BACK_AND_CHANGE')}
								</Alert>
							)}
							{sortedEventGroups.map(eventGroup => (
								<div>
									{eventGroup?.content?.ROOT?.nodes?.map(node => (
										<React.Fragment key={node}>
											<CmsContentElement
												contentId={node}
												content={eventGroup.content}
												dataFromExecutor={{
													eventGroup,
													upsellProducts: upsellProductsByEventGroup[eventGroup.id],
													selectedProductsIsNotSameTime,
													selectedDate
												}}
											/>
										</React.Fragment>
									))}
								</div>
							))}
						</div>
					)}
					{(!flags.upsellModuleWithEvents || eventGroupUids.length <= 0) &&
						orderLinesWithUpsell.map(orderLine => (
							<div>
								<Box className="px-20 py-12" style={{ backgroundColor: 'rgba(0,0,0,.1)' }}>
									<Typography className="font-bold">
										{orderLine.qty}x{' '}
										{flags.multiLanguageFunctions
											? getLocaleFromData(orderLine, 'name')
											: orderLine.name}
									</Typography>
								</Box>
								<div
									className="border-l-2 pl-20 ml-20 mt-10 mb-8"
									style={{ borderColor: 'rgba(0,0,0,.1)' }}
								>
									{Object.entries(orderLine.upsellProducts).map(
										([upsellProductUid, upsellProduct]) => {
											const amount =
												productAmount[orderLine.id] &&
												productAmount[orderLine.id][upsellProduct.followProduct.id]
													? productAmount[orderLine.id][upsellProduct.followProduct.id]
													: 0;
											const price =
												findPricesForProduct(upsellProduct.product, null, null, true, amount) /
												100;

											let toolTipTitleAdd = '';
											if (amount >= orderLine.qty) {
												toolTipTitleAdd = t('YOU_HAVE_ALREADY_ADDED_THE_MAX');
											}
											let toolTipTitleRemove = '';
											if (amount <= 0) {
												toolTipTitleRemove = t('YOU_HAVE_ALREADY_REMOVED_THE_MIN');
											}

											return (
												<div
													className="sm:flex items-center justify-between"
													key={`upsell-${upsellProductUid}`}
												>
													<Typography className="font-bold">
														{upsellProduct.product.name}
													</Typography>
													<div className="flex items-center">
														<Typography className="mr-10 font-bold whitespace-normal cursor-default">
															{price % 1 === 0 && <>{price},-</>}
															{price % 1 !== 0 && <>{moneyFormatter.format(price)}</>}
														</Typography>
														<div className="flex h-56 items-center">
															<Tooltip title={toolTipTitleRemove}>
																<span>
																	<Button
																		variant="outlined"
																		onClick={() =>
																			handleRemoveProduct(
																				orderLine,
																				upsellProduct
																			)
																		}
																		disabled={amount <= 0 || loading}
																		className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
																	>
																		<Remove />
																	</Button>
																</span>
															</Tooltip>
															<div className="flex items-center text-14 font-bold px-10">
																{amount}
															</div>

															<Tooltip title={toolTipTitleAdd}>
																<span>
																	<Button
																		variant="outlined"
																		onClick={() =>
																			handleAddProduct(orderLine, upsellProduct)
																		}
																		disabled={amount >= orderLine.qty || loading}
																		className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
																	>
																		<Add />
																	</Button>
																</span>
															</Tooltip>
														</div>
													</div>
												</div>
											);
										}
									)}
								</div>
							</div>
						))}
				</div>
				{contentElementProps.upsellButtons === 'bottom' && (
					<div className="flex flex-col items-end">
						{flags.upsellModuleWithEvents && missingError && (
							<Alert className={'mb-10 w-full'} severity={'error'}>
								{t('YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS')}
								<br />
								{missingError.map(error => (
									<div>
										- {t('ERROR_' + error.type.toUpperCase())}{' '}
										<span className={'font-bold'}>{eventGroups[error.eventGroupUid]?.name}:</span>
									</div>
								))}
								<br />
								{t('IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS')}
							</Alert>
						)}
						<div className="flex flex-col sm:flex-row">
							<Button
								autoFocus
								variant="contained"
								onClick={() => handleClose(false)}
								color={
									flags.upsellModuleWithEvents && selectedProductsIsNotSameTime
										? 'primary'
										: 'inherit'
								}
								className="rounded-none sm:w-auto w-full"
							>
								{t('CONTINUE_WITHOUT_UPSELL')}
							</Button>
							<div className="w-full sm:w-auto" style={{ margin: '0' }}>
								<LoadingButton
									onClick={() => handleClose(true)}
									variant="contained"
									color="primary"
									loading={loading || false}
									disableLoadingOnClick
									className="rounded-none"
									classes={{
										wrapper: 'sm:w-auto w-full',
										button: 'sm:w-auto w-full'
									}}
									disabled={flags.upsellModuleWithEvents && selectedProductsIsNotSameTime}
								>
									{t('CONTINUE')}
								</LoadingButton>
							</div>
						</div>
					</div>
				)}
			</DialogContent>
			{(!contentElementProps.upsellButtons || contentElementProps.upsellButtons === 'fixed') && (
				<DialogActions className="flex-col items-end">
					{flags.upsellModuleWithEvents && missingError && (
						<Alert className={'mb-10 w-full'} severity={'error'}>
							{t('YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS')}
							<br />
							{missingError.map(error => (
								<div>
									- {t('ERROR_' + error.type.toUpperCase())}{' '}
									<span className={'font-bold'}>{eventGroups[error.eventGroupUid]?.name}:</span>
								</div>
							))}
							<br />
							{t('IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS')}
						</Alert>
					)}
					<div className="flex flex-col sm:flex-row">
						<Button
							autoFocus
							variant="contained"
							onClick={() => handleClose(false)}
							color={
								flags.upsellModuleWithEvents && selectedProductsIsNotSameTime ? 'primary' : 'inherit'
							}
							className="rounded-none sm:w-auto w-full"
						>
							{t('CONTINUE_WITHOUT_UPSELL')}
						</Button>
						<div className="w-full sm:w-auto" style={{ margin: '0' }}>
							<LoadingButton
								onClick={() => handleClose(true)}
								variant="contained"
								color="primary"
								loading={loading || false}
								disableLoadingOnClick
								className="rounded-none"
								classes={{
									wrapper: 'sm:w-auto w-full',
									button: 'sm:w-auto w-full'
								}}
								disabled={flags.upsellModuleWithEvents && selectedProductsIsNotSameTime}
							>
								{t('CONTINUE')}
							</LoadingButton>
						</div>
					</div>
				</DialogActions>
			)}
		</Dialog>
	);
}
