/* eslint import/no-extraneous-dependencies: off */
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import _ from '@ameroservices-platform/shared-fuse-lodash';
import { setInitialSettings } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { showMessage } from '@ameroservices-platform/attraction-frontend/app/store/fuse/messageSlice';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

export const setUserDataFirebase = (user, authUser) => async dispatch => {
	if (user && user.data) {
		// Set user data but do not update
		return dispatch(setUserData(user));
	}

	// Create missing user settings
	return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase = authUser => async (dispatch, getState) => {
	const guestUser = getState().auth.user;
	const { currentUser } = firebase.auth();

	/**
	 * Merge with current Settings
	 */
	const user = _.merge({}, guestUser, {
		uid: authUser.uid,
		from: 'firebase',
		isAnonymous: authUser.isAnonymous,
		data: {
			displayName: authUser.displayName,
			email: authUser.email
		}
	});
	console.log(user);
	currentUser.updateProfile(user.data);

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const setUserData = user => async (dispatch, getState) => {
	/*
        You can redirect the logged-in user to a specific route depending on his role
         */

	// history.location.state = {
	// 	redirectUrl: user.redirectUrl // for example 'apps/academy'
	// };

	/*
    Set User Settings
     */
	// dispatch(setDefaultSettings(user.data.settings));

	dispatch(setUser(user));
};

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(newUser));

	return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	if (!user.role || user.role.length === 0) {
		// is guest
		return null;
	}

	firebaseService.signOut();

	dispatch(setInitialSettings());

	dispatch(userLoggedOut());

	return true;
};

export const updateUserData = user => async (dispatch, getState) => {
	firebaseService
		.updateUserData(user)
		.then(() => {
			// dispatch(showMessage({ message: 'User data saved to firebase' }));
		})
		.catch(error => {
			dispatch(showMessage({ message: error.message }));
		});
};

const initialState = {
	role: [], // guest
	data: {
		displayName: '',
		photoURL: '',
		email: '',
		shortcuts: []
	}
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState,
		setCartOrderUid: (state, action) => {
			state.cartOrderUid = action.payload;
		}
	},
	extraReducers: {}
});

export const { setUser, userLoggedOut, setCartOrderUid } = userSlice.actions;

export default userSlice.reducer;
