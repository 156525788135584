import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, Skeleton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Icon from '@mui/material/Icon';
import { selectMainTheme } from '../../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../../../store/shared/frontendSlice';
import getColor from '../../../shared/getColor';
import { getEventsByUids, selectEvents } from '../../../../../../../../store/shared/cartSlice';
import followProductVisualView from '../../../../../../../../ui-components/states/followProductVisualView';
import discountCodeDiscountCalculateType from '../discountCodeDiscountCalculateType';
import UpsellModule from '../UpsellModule';
import _ from 'lodash';
import productType from '@ameroservices-platform/shared/enums/productType';
import { getSubscriptionTypeCurrentEvent } from '@ameroservices-platform/attraction-frontend/app/main/apps/content/types/subscription/SubscriptionFirestore';
import {
	selectCart,
	selectCartLoadingById,
	selectCartOrderLines,
	updateOrderLineAmount,
	deleteOrderLine
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import CartOrderLine from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/content/CartOrderLine';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import CircularProgress from '@mui/material/CircularProgress';

function CartContentOverview(props) {
	const { addedColorOptionsForButtonsInCart } = useFlags();
	const { contentElementProps } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		numberInput: {
			'& div': {
				'&:before': {
					borderBottom: 'none'
				},
				'&:after': {
					borderBottom: 'none'
				},
				'&:hover': {
					'&:before': {
						borderBottom: 'none !important'
					},
					'&:after': {
						borderBottom: 'none !important'
					}
				}
			},
			'& input::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0
			},
			'& input': {
				width: '26px',
				color: `${getColor(
					contentElementProps.quantityNumberColor,
					contentElementProps.quantityNumberCustomColor,
					mergedTheme
				)}`,
				backgroundColor: theme.palette.secondary.light,
				padding: '10px',
				zIndex: '100',
				boxShadow: 'none',
				textAlign: 'center'
			}
		},
		buttonLeft: {
			borderBottomRightRadius: '0',
			borderTopRightRadius: '0',
			boxShadow: 'none',
			minWidth: 'auto',
			backgroundColor: theme.palette.secondary.light
		},
		buttonRight: {
			borderBottomLeftRadius: '0',
			borderTopLeftRadius: '0',
			boxShadow: 'none',
			minWidth: 'auto',
			backgroundColor: theme.palette.secondary.light
		},
		spinner: {
			color: theme.palette.primary.main
		},
		upsellButton: {
			color: `${getColor(
				contentElementProps.upsellButtonTextColor,
				contentElementProps.upsellButtonCustomTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.upsellButtonBackgroundColor,
				contentElementProps.upsellButtonCustomBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.upsellButtonTextColorHover,
					contentElementProps.upsellButtonCustomTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.upsellButtonBackgroundColorHover,
					contentElementProps.upsellButtonCustomBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();
	const events = useSelector(selectEvents);

	const cart = useSelector(selectCart);
	const orderLinesRaw = useSelector(selectCartOrderLines);
	const allOrderLinesLoading = useSelector(state => selectCartLoadingById(state, 'orderLines'));
	const orderLoading = useSelector(state => selectCartLoadingById(state, 'order'));
	const cartCheckLoading = useSelector(state => selectCartLoadingById(state, 'cartCheck'));
	const finished = useSelector(state => selectCartLoadingById(state, 'finished'));
	const totalsLoading = useSelector(state => selectCartLoadingById(state, 'totals'));
	const [confirmDeleteOrderLineUid, setConfirmDeleteOrderLineUid] = useState(null);
	const [totals, setTotals] = useState(null);
	const [upsellModal, setUpsellModal] = useState(false);
	const [subscriptionPeriods, setSubscriptionPeriods] = useState({});

	const orderLines = useMemo(() => {
		return (
			orderLinesRaw?.map(ol => {
				const followProducts = orderLinesRaw.filter(
					followProductOl =>
						followProductOl.followProductForOrderLines &&
						followProductOl.followProductForOrderLines.includes(ol.id) &&
						followProductOl.visualRelation === followProductVisualView.AS_PRODUCT
				);
				let discountCodeProducts = [];
				discountCodeProducts = orderLinesRaw.filter(
					orderLine =>
						orderLine.discountCode &&
						orderLine.discountCode.discountCalculateType ===
							discountCodeDiscountCalculateType.PER_PRODUCT &&
						orderLine.forOrderLines &&
						orderLine.forOrderLines.includes(ol.id)
				);
				return { ...ol, followProducts, discountCodeProducts };
			}) || []
		);
	}, [orderLinesRaw]);

	useEffect(() => {
		const subscriptionOrderLines = orderLinesRaw?.filter(
			orderLine => orderLine.productType === productType.SUBSCRIPTION && orderLine.subscriptionTypeUid
		);
		const subscriptionTypeUids = subscriptionOrderLines.map(orderLine => orderLine.subscriptionTypeUid);
		const uniqueSubscriptionTypeUids = subscriptionTypeUids.filter(
			(value, index, self) => self.indexOf(value) === index
		);
		Promise.all(
			uniqueSubscriptionTypeUids.map(subscriptionTypeUid =>
				getSubscriptionTypeCurrentEvent(subscriptionTypeUid).then(event => {
					if (event) {
						setSubscriptionPeriods(oldState => ({
							...oldState,
							[subscriptionTypeUid]: event
						}));
					} else {
						setSubscriptionPeriods(oldState => ({
							...oldState,
							[subscriptionTypeUid]: null
						}));
					}
				})
			)
		);
	}, [orderLinesRaw]);

	function handleOrderLineAmountChange(orderLine, amount) {
		if (finished) return;
		if (amount === 0) {
			setConfirmDeleteOrderLineUid(orderLine.id);
		} else {
			dispatch(updateOrderLineAmount(orderLines, orderLine.id, amount));
		}
	}

	function handleDelete(val) {
		if (val && !finished) {
			dispatch(deleteOrderLine(confirmDeleteOrderLineUid));
		}
		setConfirmDeleteOrderLineUid(null);
	}

	useEffect(() => {
		if (cart) {
			setTotals(cart.totals);
		}
	}, [cart]);

	useEffect(() => {
		const eventUids = orderLines
			.filter(value => value.eventUid)
			.map(m => m.eventUid)
			.filter((value, index, self) => self.indexOf(value) === index);
		if (eventUids.length > 0) {
			dispatch(getEventsByUids(eventUids));
		}

		return () => {};
	}, [orderLines, dispatch]);

	const sortedOrderLines = _.orderBy(
		orderLines,
		[
			object =>
				object.lineType === 'followProduct' ? object.visualRelation || followProductVisualView.NONE : null,
			object => events?.[object?.eventUid]?.start || null
		],
		['desc', 'asc']
	);

	return (
		<div>
			<Dialog open={confirmDeleteOrderLineUid !== null} onClose={() => handleDelete(false)}>
				<DialogTitle>{t('CART_DELETE_LINE')}</DialogTitle>
				<DialogContent dividers>{t('REMOVE_FROM_CART')}</DialogContent>
				<DialogActions>
					<Button onClick={() => handleDelete(false)}>{t('CANCEL')}</Button>
					<Button variant={'contained'} onClick={() => handleDelete(true)}>
						{t('DELETE')}
					</Button>
				</DialogActions>
			</Dialog>
			<UpsellModule
				forceOpen={upsellModal}
				onClose={() => setUpsellModal(false)}
				contentElementProps={contentElementProps}
			/>
			<Paper elevation={3} className="mb-48 mx-12 md:mx-0 relative">
				{(orderLoading || allOrderLinesLoading || cartCheckLoading) && (
					<div className="absolute w-full h-full bg-gray-700 bg-opacity-25 z-50">
						<div className="flex justify-center items-center h-full">
							<div className="flex flex-1 flex-col items-center justify-center">
								<Typography className="text-20 mb-16" color="textSecondary">
									{t('LOADING')}
								</Typography>

								<CircularProgress color="primary" />
							</div>
						</div>
					</div>
				)}
				<div className={'p-24'}>
					<Link color="primary" to="/">
						<Typography color="textPrimary" className="font-bold">
							<ArrowBackIcon />
							{t('GO_BACK')}
						</Typography>
					</Link>
					<Typography variant="h6" className={'mt-16'}>
						{t('YOUR_CART')}
					</Typography>
				</div>
				<Table className="simple">
					<TableHead>
						<TableRow>
							<TableCell className={classes.tdLeft}>{t('PRODUCT')}</TableCell>
							<Hidden only={['xs', 'sm', 'md']}>
								<TableCell align="center">{t('PER_UNIT')}</TableCell>
							</Hidden>
							<TableCell align="center">{t('QUANTITY')}</TableCell>
							<TableCell align="right">{t('TOTAL_PRICE')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedOrderLines
							.filter(orderLine => {
								if (orderLine.lineType === 'followProduct') {
									return (
										orderLine.visualRelation === followProductVisualView.NONE ||
										!orderLine.visualRelation
									);
								}
								if (orderLine.lineType === 'discountCode') {
									return false;
								}
								return true;
							})
							.map(orderLine => (
								<CartOrderLine
									orderLine={orderLine}
									classes={classes}
									subscriptionPeriods={subscriptionPeriods}
									events={events}
									onOrderLineAmountChange={handleOrderLineAmountChange}
								/>
							))}
					</TableBody>
				</Table>
				<Table className="simple mt-32">
					<TableBody>
						{orderLines.some(ol => Object.keys(ol.upsellProducts || {}).length > 0) && (
							<TableRow>
								<TableCell colSpan={2}>
									<Button
										className={addedColorOptionsForButtonsInCart && classes.upsellButton}
										endIcon={<Icon>arrow_forward</Icon>}
										onClick={() => setUpsellModal(true)}
									>
										{t('EDIT_UPSELL_OPTIONS')}
									</Button>
								</TableCell>
							</TableRow>
						)}
						{orderLines
							.filter(
								ol =>
									ol.lineType === 'discountCode' &&
									ol.discountCode &&
									ol.discountCode.discountCalculateType !==
										discountCodeDiscountCalculateType.PER_PRODUCT
							)
							.map(ol => (
								<TableRow>
									<TableCell className={classes.tdLeft}>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{ol.name}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{totalsLoading ? (
												<Skeleton
													animation={'wave'}
													className={'w-76 py-4 -my-4 inline-block'}
												/>
											) : (
												moneyFormatter.format(ol.linePrice / 100)
											)}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						{orderLines
							.filter(
								ol =>
									ol.lineType === 'followProduct' &&
									ol.visualRelation === followProductVisualView.AT_TOTAL
							)
							.map(ol => (
								<TableRow>
									<TableCell className={classes.tdLeft}>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{ol.name}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{totalsLoading ? (
												<Skeleton
													animation={'wave'}
													className={'w-76 py-4 -my-4 inline-block'}
												/>
											) : (
												moneyFormatter.format(ol.linePrice / 100)
											)}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						<TableRow>
							<TableCell className={classes.tdLeft}>
								<Typography className="font-medium" variant="subtitle1" color="textSecondary">
									Subtotal
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography className="font-medium" variant="subtitle1" color="textSecondary">
									{totalsLoading ? (
										<Skeleton animation={'wave'} className={'w-76 py-4 -my-4 inline-block'} />
									) : (
										moneyFormatter.format(totals ? totals.subtotal / 100 : 0)
									)}
								</Typography>
							</TableCell>
						</TableRow>
						{totals && totals.getByTaxPercent ? (
							Object.keys(totals.getByTaxPercent).map(key => (
								<TableRow key={key}>
									<TableCell className={classes.tdLeft}>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{t('TAX')} ({key / 100}%)
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{totalsLoading ? (
												<Skeleton
													animation={'wave'}
													className={'w-76 py-4 -my-4 inline-block'}
												/>
											) : (
												moneyFormatter.format(totals.getByTaxPercent[key] / 100)
											)}
										</Typography>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell className={classes.tdLeft}>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{t('TAX')}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{totalsLoading ? (
											<Skeleton animation={'wave'} className={'w-76 py-4 -my-4 inline-block'} />
										) : (
											moneyFormatter.format(totals ? totals.tax / 100.0 : 0)
										)}
									</Typography>
								</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell className={classes.tdLeft}>
								<Typography className="font-light" variant="h4" color="textSecondary">
									{t('TOTAL')}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography className="font-light" variant="h4" color="textSecondary">
									{totalsLoading ? (
										<Skeleton animation={'wave'} className={'w-128 py-4 -my-4 inline-block'} />
									) : (
										moneyFormatter.format(totals ? totals.total / 100 : 0)
									)}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		</div>
	);
}

export default CartContentOverview;
