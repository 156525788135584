import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfirmationDialog from '@ameroservices-platform/shared/ui-components/ConfirmationDialog';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { useTranslation } from 'react-i18next';
import {
	cartCheck,
	cartListener,
	cartOrderLinesListener,
	selectCartError,
	setCartError
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';

export default function FrontendInitialization(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const error = useSelector(selectCartError);
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation();
	const user = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (location.pathname.startsWith('/flexpos')) {
			return;
		}
		dispatch(cartCheck());
	}, [dispatch]);

	useEffect(() => {
		if (location.pathname.startsWith('/flexpos')) {
			return;
		}
		if (user && !user.cartOrderUid) {
			dispatch(cartCheck());
		}
	}, [user, dispatch]);

	// useEffect(() => {
	// 	if (location.pathname.startsWith('/flexpos')) {
	// 		return;
	// 	}
	// 	firebaseService.onAuthStateChanged(async auth => {
	// 		if (auth) {
	// 			const idTokenResult = await auth.getIdTokenResult();
	// 			if (idTokenResult.claims.organisationId) {
	// 				dispatch(requestIds());
	// 			}
	// 		}
	// 	});
	// });

	useEffect(() => {
		if (user?.cartOrderUid) {
			const unsubOrder = dispatch(cartListener(user.uid, user.cartOrderUid));
			const unsubOrderLines = dispatch(cartOrderLinesListener(user.uid, user.cartOrderUid));
			return () => {
				unsubOrder();
				unsubOrderLines();
			};
		}
		return () => {};
	}, [dispatch, user]);

	return (
		<>
			<ConfirmationDialog
				mainTheme={mainTheme}
				simple
				fullWidth
				content={error?.message ?? t(error?.messageKey)}
				keepMounted
				open={error?.type === 'dialog'}
				onClose={() => {
					dispatch(setCartError(null));
				}}
				cancelButton={false}
				title={'Fejl'}
				okButton="Luk"
			/>
			{props.children}
		</>
	);

	// this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{props.children}</>;
}
