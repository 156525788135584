import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { NavigateNext } from '@mui/icons-material';
import { Button, Paper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectModules } from '../../../../../../../../store/shared/frontendSlice';
import codeTypes from '../../../../../../../../ui-components/states/codeTypes';
import {
	selectCartOrderLines,
	selectCart,
	deleteOrderLine,
	selectCartLoadingById,
	applyCode,
	removeCode
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CartRedeemCode() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const modules = useSelector(selectModules);
	const [code, setCode] = useState('');
	const cart = useSelector(selectCart);
	const cartOrderLines = useSelector(selectCartOrderLines);
	const codeLoading = useSelector(state => selectCartLoadingById(state, 'code'));
	const [addAnotherCode, setAddAnotherCode] = useState(false);

	const codeType = useMemo(() => {
		if (modules && modules.discountCodes && modules && modules.giftCards) {
			return codeTypes.DISCOUNT_CODE_AND_GIFT_CARD;
		}
		if (modules && modules.discountCodes && !(modules && modules.giftCards)) {
			return codeTypes.DISCOUNT_CODE;
		}
		if (!(modules && modules.discountCodes) && modules && modules.giftCards) {
			return codeTypes.GIFT_CARD;
		}
		return null;
	}, [modules]);

	const codesUsed = useMemo(() => {
		const _codes = [];
		if (cart && cart.discountCodes && cart.discountCodes.length > 0) {
			_codes.push(...cart.discountCodes.map(_code => ({ ..._code, type: 'discountCode' })));
		}
		if (cart && cart.giftCards && cart.giftCards.length > 0) {
			_codes.push(...cart.giftCards.map(_code => ({ ..._code, type: 'giftCard' })));
		}
		return _codes;
	}, [cart]);

	function handleApply() {
		dispatch(applyCode(code));
		setCode('');
	}
	function handleRemove(codeToRemove) {
		const codeOrderLine = cartOrderLines.find(
			ol =>
				(ol.lineType === 'discountCode' || ol.lineType === 'giftCard') &&
				((ol.discountCode && ol.discountCode.code === codeToRemove.code) ||
					(ol.giftCard && ol.giftCard.code === codeToRemove.code))
		);
		if (!codeOrderLine) {
			dispatch(removeCode(codeToRemove.code));
		} else {
			dispatch(deleteOrderLine(codeOrderLine.id, 'code'));
		}
		setCode('');
	}
	async function handleApplyRemove() {
		if (code) {
			const codeUsed = codesUsed.find(c => c.code === code);
			if (codeUsed) {
				await handleRemove(codeUsed);
			} else {
				await handleApply();
			}
		}
		setAddAnotherCode(false);
	}

	return (
		<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
			{codeType === codeTypes.DISCOUNT_CODE && (
				<Typography variant="h6" className="p-24 pb-20">
					{t('APPLY_DISCOUNT_CODE')}
				</Typography>
			)}
			{codeType === codeTypes.GIFT_CARD && (
				<Typography variant="h6" className="p-24 pb-20">
					{t('APPLY_GIFT_CARD_CODE')}
				</Typography>
			)}
			{codeType === codeTypes.DISCOUNT_CODE_AND_GIFT_CARD && (
				<Typography variant="h6" className="p-24 pb-20">
					{t('APPLY_DISCOUNT_OR_GIFT_CARD_CODE')}
				</Typography>
			)}
			{codesUsed.map(codeUsed => (
				<div className="flex flex-col md:flex-row pb-16 px-16">
					<TextField
						label={t('CODE')}
						variant="outlined"
						className="w-full"
						size="small"
						InputProps={{ className: 'rounded-b-none md:rounded-bl-sm md:rounded-r-none' }}
						value={codeUsed.code}
						disabled
					/>
					<LoadingButton
						title={t('REMOVE')}
						variant="contained"
						color="primary"
						classes={{ button: 'w-full md:min-w-256' }}
						style={{ padding: '7px 16px' }}
						onClick={() => handleRemove(codeUsed)}
						loading={codeLoading}
					/>
				</div>
			))}
			{codesUsed.length > 0 && !addAnotherCode && (
				<div className="flex flex-col md:flex-row pb-16 px-16 -mt-6">
					<Button onClick={() => setAddAnotherCode(true)}>
						{codesUsed.some(code => code.type === 'discountCode')
							? t('CART_ADD_GIFTCARD')
							: t('CART_ADD_GIFTCARD_OR_DISCOUNTCODE')}
					</Button>
				</div>
			)}
			{(codesUsed.length <= 0 || addAnotherCode) && (
				<div className="flex flex-col md:flex-row pb-16 px-16">
					<TextField
						label={t('CODE')}
						variant="outlined"
						className="w-full"
						size="small"
						InputProps={{ className: 'rounded-b-none md:rounded-bl-sm md:rounded-r-none' }}
						value={code}
						onChange={e => {
							const val = e.currentTarget.value;
							setCode(val);
						}}
					/>
					<LoadingButton
						endIcon={
							(code || codesUsed.length <= 0) && !codesUsed.some(c => c.code === code) && <NavigateNext />
						}
						variant="contained"
						color="primary"
						className={'w-full md:w-256 rounded-l-none'}
						onClick={handleApplyRemove}
						loading={codeLoading}
					>
						{!code && codesUsed.length > 0
							? t('CANCEL')
							: codesUsed.some(c => c.code === code)
							? t('REMOVE')
							: t('APPLY')}
					</LoadingButton>
				</div>
			)}
		</Paper>
	);
}
